import React, { useState, useEffect } from "react";
import styles from "./MonthBtn.module.css";
import { TbHexagons } from "react-icons/tb";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
const YearTotalDash = ({ perm }) => {
  const fetchPermissions = async () => {
    const response = await api.get("admin/get-sub-menu-permissions/12");
    return response.data;
  };

  const navigate = useNavigate();
  // const handleSignIn = (val) => {
  //   // console.log("val", val);
  //   navigate("/borrower-list", {
  //     state: { val },
  //   });
  // };
  const handleSignIn = (val) => {
    if (perm.includes("read")) {
      navigate("/borrower-list", {
        state: { val },
      });
    } else {
      console.log("You do not have permission to view this page.");
    }
  };
  // const handleSignIn = (val) => {
  //   // console.log("val", val);
  //   navigate("/b2cAdmin/borrower-list", {
  //     state: { val },
  //   });
  // };
  const fetchData = async (searchData) => {
    const payload = {};

    if (searchData) {
      payload.bankId = searchData.bankId || "";
    }

    const response = await api.get("get-yearly-count");
    return response.data;
  };

  const DetailsResponse = useQuery({
    queryKey: ["yearCount"],
    queryFn: () => fetchData(),
    onSuccess: (data) => {
      // data?.allBankDetailsList.sort((a, b) =>
      //   a.bank_name.localeCompare(b.bank_name)
      // );
    },
  });
  const { isLoading, isFetching, data: yearCount } = DetailsResponse;
  // console.log("yearCount", yearCount);
  return (
    <div style={{ marginTop: "20px", display: "grid" }}>
      <div className={styles.monthBoxes}>
        {/* {applicationData.map((app, index) => ( */}
        <div className={styles.ttldv} onClick={() => handleSignIn("")}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11px" }}>
              Total Application(YTD)
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {yearCount?.total_application}
              </p>
              <div style={{ background: "#299cdb" }} className={styles.icocont}>
                <TbHexagons size={28} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv} onClick={() => handleSignIn("1")}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11px" }}>
              Registration(YTD)
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {yearCount?.step1_count}
              </p>
              <div style={{ background: "#25a546" }} className={styles.icocont}>
                <TbHexagons size={28} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv} onClick={() => handleSignIn("2")}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11px" }}>KYC(YTD)</p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {yearCount?.step2_count}
              </p>
              <div style={{ background: "#45cb85" }} className={styles.icocont}>
                <TbHexagons size={28} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv} onClick={() => handleSignIn("3")}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11px" }}>
              Agreement Sign(YTD)
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {yearCount?.step3_count}
              </p>
              <div style={{ background: "#f06548" }} className={styles.icocont}>
                <TbHexagons size={28} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv} onClick={() => handleSignIn("4")}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11px" }}>PDD(YTD)</p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {yearCount?.step4_count}
              </p>
              <div style={{ background: "#ffbe0b" }} className={styles.icocont}>
                <TbHexagons size={28} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11px" }}>Loan Amount</p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "20px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {yearCount?.total_loan_amount}
              </p>
              <div style={{ background: "#ffbe0b" }} className={styles.icocont}>
                <TbHexagons size={28} />
              </div>
            </div>
          </div>
        </div>
        {/* ))} */}
      </div>
    </div>
  );
};

export default YearTotalDash;
