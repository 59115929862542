import React, { useContext, useEffect } from "react";
import styles from "./BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { useToast } from "../../../context/ToastProvder";
import { Backdrop } from "@mui/material";
import ViewImg from "./ViewImg";
import { useQueries, useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { FaMinusSquare, FaPlusSquare } from "react-icons/fa";
const DealerForm = ({ defaultValue }) => {
  const { user, token, setUser, setToken, baseURL } = useContext(AuthContext);
  const [add, setAdd] = useState(false);
  const [perm, setPerm] = useState([]);
  const [formdata, setFormdata] = useState(null);
  const [selectedOption, setSelectedOption] = useState({});
  const [PFRemovedId, setPFRemovedId] = useState([]);
  const [numForms, setNumForms] = useState(
    formdata?.authorizedDirectors?.length || 1
  );
  const fetchPermissions = async () => {
    const response = await api.get("admin/get-sub-menu-permissions/49");
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["submenuPermissions"],
    queryFn: fetchPermissions,
    onSuccess: (data) => {
      setPerm(data.data);
    },
  });
  const { data: permissionsData } = PermissionsResponse;
  const [authorizedDirectors, setAuthorizedDirectors] = useState([
    {
      AuthorizedDirectorsName: "",
      AuthorizedDirectorsNumber: "",
      AuthorizedDirectorsEmail: "",
    },
  ]);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [err, setErr] = useState({});
  const navigate = useNavigate();
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { showToast } = useToast();

  const [{ data: lspList }, { data: BranchList }, { data: dealerList }] =
    useQueries({
      queries: [
        {
          queryKey: ["department"],
          queryFn: () => api.get("admin/lsp-screener").then((res) => res.data),
          initialData: [],
        },
        {
          queryKey: ["branchlist"],
          queryFn: () =>
            api
              .get("admin/get-branch-location-screener")
              .then((res) => res.data),
          initialData: [],
        },
        {
          queryKey: ["dealerList"],
          queryFn: () =>
            api.get("admin/dealer-screener-lsp").then((res) => res.data),
          initialData: [],
        },
      ],
    });
  const ExcutType = watch("exType");
  console.log("ExcutType ", ExcutType);
  useEffect(() => {
    if (defaultValue) {
      setValue("name", defaultValue?.name);
      setValue("lsp_id", defaultValue?.lsp_id);
      setValue("branch_id", defaultValue?.branch_id);
      setValue("mobile", defaultValue?.mobile);
      setValue("date_of_joining", defaultValue?.date_of_joining);
      setValue("email", defaultValue?.email);
      setValue("city", defaultValue?.city);
      setValue("district", defaultValue?.district);
      setValue("address", defaultValue?.address);
      setValue("aadhar", defaultValue?.aadhar);
      setValue("alternative_number", defaultValue?.alternative_number);
      setValue("pan", defaultValue?.pan);
      setValue("pincode", defaultValue?.pincode);
    }
  }, [defaultValue, setValue]);
  const onSubmit = async (data) => {
    console.log("Form data to submit:", data);
    const formData = new FormData();
    {
      ExcutType === "LSP" && formData.append("lsp_id", data?.lsp_id);
    }
    {
      ExcutType === "Deler" && formData.append("derler_id", data?.derler_id);
    }
    formData.append("name", data.name);
    formData.append("mobile", data.mobile);
    formData.append("branch_id", data.branch_id);
    formData.append("alternative_number", data.alternative_number);
    formData.append("email", data.email);
    formData.append("pan", data.pan);
    {
      defaultValue && formData.append("id", defaultValue.id);
    }
    formData.append("aadhar", data.aadhar);
    formData.append("exType", data.exType);
    formData.append("pincode", data.pincode);
    formData.append("date_of_joining", data.date_of_joining);
    formData.append("city", data.city);
    formData.append("district", data.district);
    formData.append("address", data.address);
    {
      !defaultValue && formData.append("password", data.password);
    }

    try {
      const apiEndpoint = defaultValue
        ? `${baseURL}admin/update-lsp-executive`
        : `${baseURL}admin/store-lsp-executive`;
      const method = defaultValue ? "post" : "post";
      // const response = await axios.post(apiEndpoint, formData, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //     Authorization: `Bearer ${token}`,
      //   },
      // });
      const response = await axios({
        method: method,
        url: apiEndpoint,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data && response.data.status) {
        const { status, message } = response.data;
        showToast(message, status);
        navigate("/lsp-executive");
        console.log("Form submitted successfully:", response.data);
      } else {
        console.error("Unexpected response format:", response);
        showToast("Unexpected response format", "error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response && error.response.data) {
        const { message, errors } = error.response.data;
        setErr(message);
        showToast(message, "error");
      } else {
        showToast("An error occurred while submitting the form", "error");
      }
    }
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  console.log("defaultValue", defaultValue);
  const currentDate = new Date().toISOString().split("T")[0];
  return (
    <div style={{ marginBottom: "15px" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <ViewImg addHandle={addHandle} zoomImgUrl={rowToEdit} />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.searchformasc}>
          <div className={styles.totalcheckconnn}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Name<span className={styles.requ}>*</span>
              </label>
              <input
                placeholder="Enter Name"
                {...register("name", { required: true })}
                type="text"
                className={styles.inpttsc}
              />
              {errors.name && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Mobile<span className={styles.requ}>*</span>
              </label>
              <input
                placeholder="Enter Mobile"
                {...register("mobile", { required: true })}
                type="number"
                className={styles.inpttsc}
              />
              {errors.mobile && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Email<span className={styles.requ}>*</span>
              </label>
              <input
                placeholder="Enter Email"
                {...register("email", { required: true })}
                type="email"
                className={styles.inpttsc}
              />
              {errors.email && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Executive Type<span className={styles.requ}>*</span>
              </label>
              <select
                {...register("exType", { required: true })}
                className={styles.inpttsc}
                style={{ height: "30px" }}
              >
                <option value="">--Select Executive Type--</option>
                <option value="LSP">LSP</option>
                <option value="Dealer">Dealer</option>
              </select>

              {errors.exType && (
                <span className={styles.error}>{"This Field is Missing."}</span>
              )}
            </div>
            {user?.user_type === "Super Admin" && ExcutType === "Dealer" && (
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Dealer<span className={styles.requ}>*</span>
                </label>
                <select
                  {...register("dealer_id", { required: true })}
                  className={styles.inpttsc}
                  style={{ height: "30px" }}
                >
                  <option value="">--Select Dealer--</option>
                  {dealerList?.data?.map((row, i) => (
                    <option key={i} value={row.id}>
                      {row.name}
                    </option>
                  ))}
                </select>

                {errors.dealer_id && (
                  <span className={styles.error}>
                    {"This Field is Missing."}
                  </span>
                )}
              </div>
            )}
            {user?.user_type === "Super Admin" && ExcutType === "LSP" && (
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  LSP<span className={styles.requ}>*</span>
                </label>
                <select
                  {...register("lsp_id", { required: true })}
                  className={styles.inpttsc}
                  style={{ height: "30px" }}
                >
                  <option value="">--Select LSP--</option>
                  {lspList?.data?.map((row, i) => (
                    <option key={i} value={row.id}>
                      {row.name}
                    </option>
                  ))}
                </select>

                {errors.lsp_id && (
                  <span className={styles.error}>
                    {"This Field is Missing."}
                  </span>
                )}
              </div>
            )}
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Branch<span className={styles.requ}>*</span>
              </label>
              <select
                {...register("branch_id", { required: true })}
                className={styles.inpttsc}
                style={{ height: "30px" }}
              >
                <option value="">--Select Branch--</option>
                {BranchList?.data?.map((row, i) => (
                  <option key={i} value={row.id}>
                    {row.location_name}
                  </option>
                ))}
              </select>

              {errors.branch_id && (
                <span className={styles.error}>{"This Field is Missing."}</span>
              )}
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Date of Joining
              </label>
              <input
                type="date"
                className={styles.inpttsc}
                max={currentDate}
                {...register("date_of_joining")}
              />
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                City<span className={styles.requ}>*</span>
              </label>
              <input
                placeholder="Enter City"
                type="text"
                className={styles.inpttsc}
                {...register("city", {
                  required: true,
                })}
              />
              {errors.city && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                District<span className={styles.requ}>*</span>
              </label>
              <input
                placeholder="Enter District"
                type="text"
                className={styles.inpttsc}
                {...register("district", {
                  required: true,
                })}
              />
              {errors.district && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Pincode<span className={styles.requ}>*</span>
              </label>
              <input
                placeholder="Enter Pincode"
                type="number"
                className={styles.inpttsc}
                {...register("pincode", {
                  required: true,
                  pattern: {
                    value: /^\d{6}$/,
                    message: "Please Enter Valid Pincode",
                  },
                })}
              />
              {errors.pincode && (
                <span className={styles.error}>
                  {errors.pincode.message || "This Field is Missing."}
                </span>
              )}
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Address<span className={styles.requ}>*</span>
              </label>
              <input
                placeholder="Enter Address"
                type="text"
                className={styles.inpttsc}
                {...register("address", {
                  required: true,
                })}
              />
              {errors.address && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Alternative Number
              </label>
              <input
                placeholder="Enter Alternative Number"
                type="number"
                className={styles.inpttsc}
                {...register("alternative_number")}
              />
              {/* {errors.alternative_number && (
                <span className={styles.error}>This Field is Missing.</span>
              )} */}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                PAN No.
              </label>
              <input
                placeholder="Enter PAN Number"
                type="text"
                className={styles.inpttsc}
                onInput={(e) => {
                  e.target.value = e.target.value.toUpperCase();
                }}
                {...register("pan", {
                  pattern: {
                    value: /^[A-Z0-9]{10}$/,
                    message: "Please Enter Valid PAN Number",
                  },
                })}
              />
              {errors.pan && (
                <span className={styles.error}>{errors.pan.message}</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Aadhar Number
              </label>
              <input
                placeholder="Enter Aadhar Number"
                type="text"
                className={styles.inpttsc}
                {...register("aadhar", {
                  pattern: {
                    value: /^[A-Z0-9]{12}$/,
                    message: "Please Enter Aadhar Number",
                  },
                })}
              />

              {errors.aadhar && (
                <span className={styles.error}>{errors.aadhar.message}</span>
              )}
            </div>
            {!defaultValue && (
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Password<span className={styles.requ}>*</span>
                </label>
                <input
                  placeholder="Enter Password"
                  {...register("password", { required: true })}
                  type="password"
                  className={styles.inpttsc}
                />
                {errors.alternative_number && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>
            )}
          </div>

          {perm?.includes("create") && (
            <div className={styles.buttonasc}>
              {defaultValue && (
                <button type="submit" className={styles.Subbtn}>
                  Update
                </button>
              )}
            </div>
          )}
          <div className={styles.buttonasc}>
            {!defaultValue && (
              <button type="submit" className={styles.Subbtn}>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default DealerForm;
