import React from "react";
import classes from "./Language.module.css";
import { useForm } from "react-hook-form";
const SelectModal = () => {
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  return (
    <div>
      <p
        style={{
          textAlign: "left",
          padding: "10px 0",
          fontSize: "12px",
          fontWeight: "bold",
          color: "#212529",
        }}
      >
        SELECT MODAL
      </p>{" "}
      <div className={classes.totalcheckfhfnn}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
          className={classes.shdwtpbx}
        >
          <div
            style={{
              display: "flex",

              gap: "5px",
            }}
          >
            <input type="radio"></input>
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                textAlign: "left",
                color: "#212529",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              <p>ECO NXE GMS</p>
              <p
                style={{
                  fontSize: "12px",
                }}
              >
                Lead Acid
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",

              textAlign: "right",
              color: "#212529",
            }}
          >
            <p
              style={{
                fontSize: "11px",
              }}
            >
              Price
            </p>
            <p
              style={{
                fontSize: "13px",
                color: "#25a546",
                fontWeight: "bold",
              }}
            >
              1530000.00
            </p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
          className={classes.shdwtpbx}
        >
          <div
            style={{
              display: "flex",

              gap: "5px",
            }}
          >
            <input type="radio"></input>
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                textAlign: "left",
                color: "#212529",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              <p>ECO NXE GMS</p>
              <p
                style={{
                  fontSize: "12px",
                }}
              >
                Lead Acid
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",

              textAlign: "right",
              color: "#212529",
            }}
          >
            <p
              style={{
                fontSize: "11px",
              }}
            >
              Price
            </p>
            <p
              style={{
                fontSize: "13px",
                color: "#25a546",
                fontWeight: "bold",
              }}
            >
              1530000.00
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
          className={classes.shdwtpbx}
        >
          <div
            style={{
              display: "flex",

              gap: "5px",
            }}
          >
            <input type="radio"></input>
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                textAlign: "left",
                color: "#212529",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              <p>ECO NXE GMS</p>
              <p
                style={{
                  fontSize: "12px",
                }}
              >
                Lead Acid
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",

              textAlign: "right",
              color: "#212529",
            }}
          >
            <p
              style={{
                fontSize: "11px",
              }}
            >
              Price
            </p>
            <p
              style={{
                fontSize: "13px",
                color: "#25a546",
                fontWeight: "bold",
              }}
            >
              1530000.00
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
          className={classes.shdwtpbx}
        >
          <div
            style={{
              display: "flex",

              gap: "5px",
            }}
          >
            <input type="radio"></input>
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                textAlign: "left",
                color: "#212529",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              <p>ECO NXE GMS</p>
              <p
                style={{
                  fontSize: "12px",
                }}
              >
                Lead Acid
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",

              textAlign: "right",
              color: "#212529",
            }}
          >
            <p
              style={{
                fontSize: "11px",
              }}
            >
              Price
            </p>
            <p
              style={{
                fontSize: "13px",
                color: "#25a546",
                fontWeight: "bold",
              }}
            >
              1530000.00
            </p>
          </div>
        </div>
      </div>
      <div className={classes.totalcheckconnn} style={{ margin: "15px 0" }}>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Loan Amount
            <span className={classes.requ}>
              *{" "}
              <span style={{ fontSize: "10px" }}>
                Minumun Loan Amount : ₹120000
              </span>
            </span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="number"
            step="0.01"
            className={classes.inputstst}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Loan Term in Month(s)
            <span className={classes.requ}>
              *
              <span style={{ fontSize: "10px" }}>Minumun Loan Term : 21 M</span>
            </span>
          </label>
          {/* <input
                  // placeholder="Enter Name"
                  {...register("name", { required: true })}
                  type="text"
                  className={classes.inputstst}
                /> */}
          <select
            {...register("hjreh", { required: true })}
            className={classes.inputstst}
            style={{ height: "29px" }}
          >
            <option>--Select Loan Term--</option>
          </select>
          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
      </div>
      <div
        className={classes.buttonasc}
        style={{ marginTop: "10px", textAlign: "left" }}
      >
        {/* {!defaultValue && ( */}
        <button
          type="submit"
          className={classes.Subbtn}
          style={{ width: "80px" }}
        >
          Submit
        </button>
        {/* )} */}
      </div>
    </div>
  );
};

export default SelectModal;
