import React, { useEffect, useState } from "react";
import styles from "./Modal.module.css";
import { style } from "@mui/system";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import sampleimg from "./../../../icons/sampleimg.png";
import ZoomImg from "./ZoomImg";
import { Backdrop } from "@mui/material";
const Modal = ({ addHandle, defaultValue, borrowerId, doc, type }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm();
  // useEffect(() => {
  //   if (defaultValue) {
  //     setValue("startDate", defaultValue.startDate);
  //     setValue("vpfAmount", defaultValue.amount);
  //   }
  // }, [defaultValue, setValue]);
  const [bimg, setBimg] = useState(false);
  const [zoomImgUrl, setZoomImgUrl] = useState(null);

  const imgHandle = (url) => {
    setZoomImgUrl(url);
    setBimg((val) => !val);
  };
  const getFullUrl = (path) =>
    path ? `https://insta.finayo.tech${path}` : null;

  const renderImages = (imagePaths) => {
    return imagePaths
      .split(",")
      .map((path, index) => (
        <img
          key={index}
          src={getFullUrl(path)}
          style={{ width: "150px", height: "100px" }}
          alt={`Signed Cheque ${index + 1}`}
          onClick={() => imgHandle(getFullUrl(path))}
        />
      ));
  };
  return (
    <form
      style={{ minWidth: "400px", padding: "0 10px " }}
      className={styles.modalcontainerr}
      onClick={(event) => event.stopPropagation()}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={bimg}
        onClick={imgHandle}
      >
        <ZoomImg
          addHandle={imgHandle}
          zoomImgUrl={zoomImgUrl}
          borrowerId={borrowerId}
        />
      </Backdrop>
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>
          Documents{" "}
          {type === "borrower"
            ? "of Borrower"
            : type === "coapplicant"
            ? "of Co-Applicant"
            : "Of Guarantor"}
        </h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red", display: "flex", gap: "10px" }}
      >
        <div className={styles.modalform}>
          {type === "borrower" && doc.borrower_aadhar_front && (
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Aadhar Card
            </p>
          )}
          <div style={{ display: "flex", gap: "10px" }}>
            {" "}
            {type === "borrower" && doc.borrower_aadhar_front && (
              <img
                src={getFullUrl(doc.borrower_aadhar_front)}
                style={{ width: "150px", height: "100px" }}
                alt="Aadhar Front"
                onClick={() => imgHandle(getFullUrl(doc.borrower_aadhar_front))}
              />
            )}
            {type === "borrower" && doc.borrower_aahar_back && (
              <img
                src={getFullUrl(doc.borrower_aahar_back)}
                style={{ width: "150px", height: "100px" }}
                alt="Aadhar Back"
                onClick={() => imgHandle(getFullUrl(doc.borrower_aahar_back))}
              />
            )}
          </div>
        </div>
        {type === "borrower" && doc.borrower_avtar && (
          <div className={styles.modalform}>
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Image
            </p>
            <div style={{ display: "flex", gap: "10px" }}>
              {" "}
              <img
                src={getFullUrl(doc.borrower_avtar)}
                style={{ width: "150px", height: "100px" }}
                alt="Image"
                onClick={() => imgHandle(getFullUrl(doc?.borrower_avtar))}
              />
            </div>
          </div>
        )}
        <div className={styles.modalform}>
          {type === "borrower" && doc.borrower_per_address_proof && (
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Address Proof
            </p>
          )}
          <div style={{ display: "flex", gap: "10px" }}>
            {" "}
            {type === "borrower" && doc.borrower_per_address_proof && (
              <img
                src={getFullUrl(doc.borrower_per_address_proof)}
                style={{ width: "150px", height: "100px" }}
                alt="Address Proof"
                onClick={() =>
                  imgHandle(getFullUrl(doc?.borrower_per_address_proof))
                }
              />
            )}
            {type === "borrower" && doc.borrower_res_address_proof && (
              <img
                src={getFullUrl(doc.borrower_res_address_proof)}
                style={{
                  width: "150px",
                  height: "100px",
                  position: "relative",
                  top: "23px",
                }}
                alt="Address Proof"
                onClick={() =>
                  imgHandle(getFullUrl(doc?.borrower_res_address_proof))
                }
              />
            )}
          </div>
        </div>
      </div>
      <div style={{ display: "flex", gap: "10px" }}>
        {type === "borrower" && doc.borrower_pan_doc && (
          <div
            className={styles.modalform}
            style={{ marginTop: "10px", padding: "10px" }}
          >
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              PAN Card
            </p>
            <div style={{ display: "flex", gap: "10px" }}>
              <img
                src={getFullUrl(doc.borrower_pan_doc)}
                style={{ width: "150px", height: "100px" }}
                alt="PAN Card"
                onClick={() => imgHandle(getFullUrl(doc?.borrower_pan_doc))}
              />
            </div>
          </div>
        )}
        {type === "borrower" && doc.borrower_driving_license && (
          <div
            className={styles.modalform}
            style={{ marginTop: "10px", padding: "10px" }}
          >
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Driving License
            </p>
            <div style={{ display: "flex", gap: "10px" }}>
              <img
                src={getFullUrl(doc.borrower_driving_license)}
                style={{ width: "150px", height: "100px" }}
                alt="Driving License"
                onClick={() =>
                  imgHandle(getFullUrl(doc?.borrower_driving_license))
                }
              />
            </div>
          </div>
        )}
        {type === "borrower" && doc.borrower_signed_cheque && (
          <div
            className={styles.modalform}
            style={{ marginTop: "10px", padding: "10px 0" }}
          >
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Signed Cheque
            </p>
            <div style={{ display: "flex", gap: "10px" }}>
              {/* <img
                src={getFullUrl(doc.borrower_signed_cheque)}
                style={{ width: "150px", height: "100px" }}
                alt="PAN Card"
              /> */}
              {renderImages(doc.borrower_signed_cheque)}
            </div>
          </div>
        )}
      </div>

      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            {/* <button className={styles.closebtn} onClick={addHandle}>
              Close
            </button> */}
            {/* <button type="submit" className={styles.Subbtn}>
              Submit
            </button> */}
          </div>
        </div>
      </div>
    </form>
  );
};

export default Modal;
