import React, { useState } from "react";
import classes from "./Language.module.css";
import Breadcum from "../../Common/Breadcum";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import SelectModal from "./SelectModal";
import StepSecond from "./StepSecond";
const StepTwo = () => {
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [type, setType] = useState(null);
  const [totalPage, setTotalPage] = useState(2);
  const navigate = useNavigate();
  const handleEditRow = (typee) => {
    setType(typee);
    // navigate("/someotherpage", {
    //   state: { type },
    // });
  };
  console.log({ type });
  const location = useLocation();
  const { val } = location.state || "";
  console.log("val", val);
  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      <div className={classes.mainBodyContainer}>
        <Breadcum pageName="Step 2" />
        <div className={classes["main-body"]} style={{ padding: "15px" }}>
          <StepSecond />
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
