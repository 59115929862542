import React, { useState } from "react";
import classes from "./Language.module.css";
import { RxCheckCircled, RxCrossCircled } from "react-icons/rx";

const AdditionalThirteen = () => {
  const [show, setShow] = useState(false);
  const HandleShow = () => {
    setShow(true);
  };
  return (
    <div>
      <p className={classes.applheader}>LOAN AGREEMENT GENERATION</p>
      <div className={classes.totalcheckconnn}>
        {/* start */}
        <div className={classes.applylonffer}>
          <p
            style={{ padding: "6px", fontSize: "13px" }}
            className={classes.applyluptxt}
          >
            Borrower Details
          </p>
          <div className={classes.divfrtheredr}>
            <div className={classes.notAppre}>
              {/* <RxCheckCircled size="20" /> */}
              <RxCrossCircled size="25" />
            </div>
            <div className={classes.applylonfthn}>
              <div className={classes.divinffdffg}>
                <p className={classes.txtfoofl}>Name</p>
                <p className={classes.txtfooflbtoneffs}>Surjava</p>
              </div>
              <div className={classes.divinffdffg}>
                <p className={classes.txtfoofl}>Mobile</p>
                <p className={classes.txtfooflbtoneffs}>XXXXXXXXXX</p>
              </div>
              <div className={classes.divinffdffg}>
                <p className={classes.txtfoofl}>Email</p>
                <p className={classes.txtfooflbtoneffs}>xyz@gmail.com</p>
              </div>
              <div className={classes.divinffdffg}>
                <p className={classes.txtfoofl}>City</p>
                <p className={classes.txtfooflbtoneffs}>Kolkata</p>
              </div>
            </div>
          </div>
        </div>
        {/* emd */}
      </div>
      <div
        className={classes.buttonasc}
        style={{ marginTop: "10px", textAlign: "left" }}
      >
        {/* {!defaultValue && ( */}
        <button
          type="submit"
          className={classes.Subbtn}
          style={{
            width: "90px",
          }}
        >
          Genrate
        </button>
      </div>
    </div>
  );
};

export default AdditionalThirteen;
