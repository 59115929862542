import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./Language.module.css";
const StepFoutte = () => {
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [type, setType] = useState(null);
  const [totalPage, setTotalPage] = useState(2);
  const navigate = useNavigate();
  const handleEditRow = (typee) => {
    setType(typee);
    // navigate("/someotherpage", {
    //   state: { type },
    // });
  };
  console.log({ type });
  const location = useLocation();
  const { val } = location.state || "";
  console.log("val", val);
  return (
    <div className={classes.tablco}>
      <p className={classes.applheader}>Vehicle Document</p>
      <div className={classes.totalcheckconnnforimgsec}>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Vehicle Image
            <span className={classes.requ}>*</span>
          </label>
          <input
            // placeholder="Enter Name"
            {...register("name", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            RTO Document<span className={classes.requ}>*</span>
          </label>
          <input
            // placeholder="Enter Name"
            {...register("dob", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.dob && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            RTO Tax Receipt<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Insurance<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Invoice<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            MMR
            <span className={classes.requ}>*</span>
          </label>
          <input
            // placeholder="Enter Name"
            {...register("name", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            DOH<span className={classes.requ}>*</span>
          </label>
          <input
            // placeholder="Enter Name"
            {...register("dob", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.dob && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Chassis Number<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Battery Image<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Motor Controller<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Registration Certificate<span className={classes.requ}>*</span>
          </label>
          <input
            // placeholder="Enter Name"
            {...register("name", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Vehicle Signed Cheque 1<span className={classes.requ}>*</span>
          </label>
          <input
            // placeholder="Enter Name"
            {...register("dob", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.dob && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Vehicle Signed Cheque 2<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Other Vehicle Document 1
          </label>
          <input {...register("ff")} type="file" className={classes.uploadbx} />
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Other Vehicle Document 2
          </label>
          <input {...register("ff")} type="file" className={classes.uploadbx} />
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Other Vehicle Document 3
          </label>
          <input {...register("ff")} type="file" className={classes.uploadbx} />
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Other Vehicle Document 4
          </label>
          <input {...register("ff")} type="file" className={classes.uploadbx} />
        </div>
      </div>

      <div
        className={classes.buttonasc}
        style={{ marginTop: "10px", textAlign: "left" }}
      >
        {/* {!defaultValue && ( */}
        <button
          type="submit"
          className={classes.Subbtn}
          style={{ width: "80px", marginTop: "10px" }}
        >
          Submit
        </button>
        {/* )} */}
      </div>
    </div>
  );
};

export default StepFoutte;
