import React from "react";
import classes from "./Language.module.css";
import { useForm } from "react-hook-form";
const AdditionalSix = () => {
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  return (
    <div
      style={{
        marginTop: "10px",
        borderTop: "1px solid #ddd",
        padding: "15px 0",
      }}
    >
      {" "}
      <div className={classes.totalcheckconnn}>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Full Name<span className={classes.requ}>*</span>
          </label>
          <input
            // placeholder="Enter Name"
            {...register("name", { required: true })}
            type="text"
            className={classes.inputstst}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            DOB<span className={classes.requ}>*</span>
          </label>
          <input
            // placeholder="Enter Name"
            {...register("dob", { required: true })}
            type="date"
            className={classes.inputstst}
          />

          {errors.dob && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Email Id<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="email"
            className={classes.inputstst}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Alternate Mobile Number<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="number"
            className={classes.inputstst}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Gender<span className={classes.requ}>*</span>
          </label>

          <select
            {...register("hjreh", { required: true })}
            className={classes.inputstst}
            style={{ height: "29px" }}
          >
            <option>--Select Gender--</option>
          </select>
          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Father's Name<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="text"
            className={classes.inputstst}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Relation with Borrower<span className={classes.requ}>*</span>
          </label>

          <select
            {...register("hjreh", { required: true })}
            className={classes.inputstst}
            style={{ height: "29px" }}
          >
            <option>--Select Relation with Borrower--</option>
          </select>
          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
      </div>
      <hr style={{ border: "0.5px solid #ddd", margin: "20px 0" }} />
      <p className={classes.applheader}>GUARANTOR OCCUPATIONS :</p>
      <div className={classes.totalcheckconnn}>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Resident Type<span className={classes.requ}>*</span>
          </label>
          {/* <input
                  // placeholder="Enter Name"
                  {...register("name", { required: true })}
                  type="text"
                  className={classes.inputstst}
                /> */}
          <select
            {...register("name", { required: true })}
            className={classes.inputstst}
            style={{ height: "29px" }}
          >
            <option>--Select Resident Type--</option>
          </select>
          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Permanent Address<span className={classes.requ}>*</span>
          </label>
          <textarea
            style={{ height: "35px" }}
            placeholder="Enter Permanent Address"
            {...register("perm", { required: true })}
            type="text"
            className={classes.inputstst}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Present/Communication Address<span className={classes.requ}>*</span>
          </label>
          <textarea
            style={{ height: "35px" }}
            placeholder="Enter Present/Communication Address"
            {...register("ff", { required: true })}
            type="text"
            className={classes.inputstst}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div style={{ paddingTop: "15px", textAlign: "left" }}>
          <input
            {...register("ff", { required: true })}
            type="checkbox"
            className={classes.inputstst}
          />
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              position: "relative",
              bottom: "3px",
            }}
          >
            Same as Permanent Address
          </label>

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Occupation Type<span className={classes.requ}>*</span>
          </label>

          <select
            {...register("name", { required: true })}
            className={classes.inputstst}
            style={{ height: "29px" }}
          >
            <option>--Select Occupation Type--</option>
          </select>
          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Current Occupation<span className={classes.requ}>*</span>
          </label>
          <input
            placeholder="Enter Current Occupation"
            {...register("perm", { required: true })}
            type="text"
            className={classes.inputstst}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Driving Exprience<span className={classes.requ}>*</span>
          </label>
          <input
            placeholder="Enter Driving Exprience"
            {...register("perm", { required: true })}
            type="number"
            className={classes.inputstst}
          />
          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Vehicle Type<span className={classes.requ}>*</span>
          </label>

          <select
            {...register("name", { required: true })}
            className={classes.inputstst}
            style={{ height: "29px" }}
          >
            <option>--Select Vehicle Type--</option>
          </select>
          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Monthly Income<span className={classes.requ}>*</span>
          </label>
          <input
            placeholder="Enter Monthly Income"
            {...register("ff", { required: true })}
            type="text"
            className={classes.inputstst}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Expected Monthly Income<span className={classes.requ}>*</span>
          </label>
          <input
            placeholder="Enter Expected Monthly Income"
            {...register("ff", { required: true })}
            type="number"
            className={classes.inputstst}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Other Income<span className={classes.requ}>*</span>
          </label>
          <input
            placeholder="Enter Other Income"
            {...register("ff", { required: true })}
            type="number"
            className={classes.inputstst}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Extra Source of Income<span className={classes.requ}>*</span>
          </label>
          <input
            placeholder="Enter Extra Source of Income"
            {...register("ff", { required: true })}
            type="number"
            className={classes.inputstst}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Total Yearly Income<span className={classes.requ}>*</span>
          </label>
          <input
            placeholder="Enter Total Yearly Income"
            {...register("ff", { required: true })}
            type="number"
            className={classes.inputstst}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
      </div>
      <div
        className={classes.buttonasc}
        style={{ marginTop: "10px", textAlign: "left" }}
      >
        {/* {!defaultValue && ( */}
        <button
          type="submit"
          className={classes.Subbtn}
          style={{ width: "80px", marginTop: "10px" }}
        >
          Submit
        </button>
        {/* )} */}
      </div>
    </div>
  );
};

export default AdditionalSix;
