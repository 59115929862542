import React, { useContext, useEffect, useState } from "react";
import classes from "./Sidenav.module.css";
import finayo_logo from "./../../../icons/finayo_logo.png";
import { useNavigate } from "react-router-dom";
import { FaRegCircle } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import Menu from "./Menu";
import { useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import { AuthContext, useAuth } from "../../../context/AuthContext";

export default function SideNav({ className, onHide }) {
  const [menuItems, setMenuItems] = useState([]);
  // const [paths, setPaths] = useState([]);
  // const handleMenuItemClick = (menuId) => {
  //   setMenuItems((prevMenuItems) =>
  //     prevMenuItems.map((item) => ({
  //       ...item,
  //       isOpen: item.id === menuId ? !item.isOpen : item.isOpen,
  //     }))
  //   );
  // };
  const navigate = useNavigate();
  // const handleDashboardClick = () => {
  //   navigate("/b2cAdmin/dashboard");
  // };
  const handleDashboardClick = () => {
    navigate("/dashboard");
  };
  const fetchData = async () => {
    const response = await api.get("admin/get-user-sidebar-menu");
    return response.data;
  };
  // console.log("paths", paths);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("admin/get-user-sidebar-menu");
        const data = response?.data?.data || [];
        setMenuItems(data.map((item) => ({ ...item, isOpen: false })));
        // const extractedPaths = [];
        // data.forEach((item) => {
        //   item.menus.forEach((menu) => {
        //     menu.submenus.forEach((submenu) => {
        //       if (submenu.path) {
        //         extractedPaths.push(submenu.path); // Collect paths
        //       }
        //     });
        //   });
        // });
        // setPaths(extractedPaths);
      } catch (error) {
        if (error?.response?.status === 401) {
          console.log("Unauthorized - Logging out the user.");
          const token = localStorage.getItem("ACCESS_TOKEN"); // Get the token from localStorage
          if (token) {
            localStorage.removeItem("ACCESS_TOKEN"); // Remove the token from localStorage
            console.log("Token removed from localStorage:", token);
            console.log("CODE:SIDENAV");
          }
        }
        // console.error(
        //   "Failed to fetch sidebar menu data:",
        //   error?.response?.data.message
        // );
        // if (error?.response?.data?.message === "Unauthenticated") {
        //   console.log("Unauthenticated");
        //   setToken(null);
        // }
      }
    };

    fetchData();
  }, []);

  const handleMenuItemClick = (menuId) => {
    setMenuItems((prevMenuItems) =>
      prevMenuItems.map((item) => ({
        ...item,
        isOpen: item.id === menuId ? !item.isOpen : false,
      }))
    );
  };
  // const DetailsResponse = useQuery({
  //   queryKey: ["sidemenuq"],
  //   queryFn: fetchData,
  //   onSuccess: (data) => {
  //     setMenuItems(data?.data.map((item) => ({ ...item, isOpen: false })));
  //   },
  // });

  // const { isLoading, isFetching, data: SideNavData } = DetailsResponse;
  // const handleMenuItemClick = (menuId) => {
  //   setMenuItems((prevMenuItems) => {
  //     const updatedMenuItems = prevMenuItems.map((item) => ({
  //       ...item,
  //       isOpen: item.id === menuId ? !item.isOpen : false,
  //     }));

  //     // Log the updated isOpen state for each item
  //     updatedMenuItems.forEach((item) => {
  //       console.log(`Item ID: ${item.id}, isOpen: ${item.isOpen}`);
  //     });

  //     return updatedMenuItems;
  //   });
  // };
  const { activeSubmenuId, setActiveSubmenuId } = useAuth();
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname.replace("/", ""); // Get current path without leading '/'
    console.log("currentPath", currentPath);

    // Traverse through menus and submenus to find the active submenu
    menuItems.forEach((item) => {
      item?.menus?.forEach((menu) => {
        menu?.submenus?.forEach((submenu) => {
          if (submenu.path === currentPath) {
            setActiveSubmenuId(submenu?.id); // Set the active submenu ID
          }
        });
      });
    });
  }, [location.pathname, menuItems, setActiveSubmenuId]);
  console.log("activeSubmenuId", activeSubmenuId);
  return (
    <div className={classes.containerNav}>
      <nav className={`${classes.container} ${className}`}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            background: "white",
            padding: "10px",
          }}
        >
          <img src={finayo_logo} style={{ width: "150px" }} />
          <div className={classes.cutBtn} onClick={onHide}>
            <FaRegCircle />
          </div>
        </div>
        <h4
          style={{
            textAlign: "left",
            padding: " 12px 20px",
            margin: "0px",
            fontSize: "11px",
            color: "#9b9eb1",
          }}
        >
          MENU
        </h4>
        <li onClick={handleDashboardClick}>
          <div className={classes.mainitem}>
            <h2 className={classes["item-label"]}>Dashboard</h2>
          </div>
        </li>
        {menuItems?.map((item) => (
          <li key={item.id} onClick={() => handleMenuItemClick(item.id)}>
            {item?.menus?.length > 0 && (
              <div className={classes.mainitem}>
                <h2
                  // className={`${item.isOpen ? "active" : ""} ${
                  //   classes["item-label"]
                  // }`}
                  className={`${item.isOpen ? classes.active : ""} ${
                    classes["item-label"]
                  }`}
                >
                  {item.name}
                </h2>
                {item?.menus?.length > 0 &&
                  (item.isOpen ? (
                    <IoIosArrowDown
                      className={classes.icntb}
                      style={{ width: "10px", color: "#cacddc" }}
                    />
                  ) : (
                    <IoIosArrowForward
                      className={classes.icntb}
                      style={{ width: "10px", color: "#cacddc" }}
                    />
                  ))}
              </div>
            )}
            {item.isOpen && (
              <ul
                className={classes.submenuCon}
                onClick={(e) => e.stopPropagation()}
              >
                {item?.menus?.map((menu) => (
                  <Menu menu={menu} Mkey={menu?.id} />
                ))}
              </ul>
            )}
          </li>
        ))}
      </nav>
    </div>
  );
}
