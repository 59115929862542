import React, { useContext, useEffect } from "react";
import styles from "./Language.module.css";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { useToast } from "../../../context/ToastProvder";
import { Backdrop, Dialog } from "@mui/material";
import ViewImg from "../ViewDeDetails/ViewImg";
// import ViewImg from "./ViewImg";
import { useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
const DealerDet = ({ defaultValue, existingData }) => {
  const [add, setAdd] = useState(false);
  const { token, baseURL, baseDoc } = useContext(AuthContext);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [err, setErr] = useState({});
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [pdfUrl, setPdfUrl] = React.useState("");
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { showToast } = useToast();
  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // backgroundColor: "rgba(0, 0, 0, 0.5)",
    pointerEvents: "none",
  };
  // const handleViewPdf = (pdfUrl) => {
  //   if (pdfUrl) {
  //     window.open(pdfUrl, "_blank");
  //   }
  // };
  // const handleViewPdf = (pdfUrl) => {
  //   if (pdfUrl) {
  //     const fullUrl = `${baseDoc}${pdfUrl}`;
  //     window.open(fullUrl, "_blank");
  //   }
  // };
  const handleViewPdf = (url) => {
    const fullUrl = `${baseDoc}${url}`;
    setPdfUrl(fullUrl);
    setOpen(true);
  };

  const disableRightClick = (e) => {
    e.preventDefault();
  };
  //   const fetchData = async () => {
  //     const response = await api.get(`edit-dealer/${defaultValue.id}`);
  //     return response.data;
  //   };
  //   const DetailsResponse = useQuery({
  //     queryKey: ["bankDetailsList"],
  //     queryFn: () => fetchData(),
  //     onSuccess: (data) => {
  //       setdefaultValue(data?.data);
  //     },
  //   });
  //   const { isLoading, isFetching, data: dashboardList } = DetailsResponse;
  //   console.log("defaultValue", defaultValue);

  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };

  return (
    <div style={{ marginBottom: "15px" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <ViewImg addHandle={addHandle} zoomImgUrl={rowToEdit} />
      </Backdrop>
      <div>
        <div className={styles.totalcheckconnn} style={{ marginTop: "10px" }}>
          {/* <div className={styles.fourinputasc}> */}
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>Dealer Name</label>
            <label className={styles.pbtfrtxt}>{existingData?.name}</label>
          </div>
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>Company Type</label>
            <label className={styles.pbtfrtxt}>
              {existingData?.company_type}
            </label>
          </div>
          {existingData?.company_type === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label className={styles.pbtfrtxt}>Date Of Incorporation</label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {existingData?.dob}
              </label>
            </div>
          )}
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>Mobile Number</label>
            <label className={styles.pbtfrtxt}>{existingData?.mobile}</label>
          </div>
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>Alternate Mobile Number</label>
            <label className={styles.pbtfrtxt}>
              {existingData?.alternative_number}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>Email</label>
            <label className={styles.pbtfrtxt}>{existingData?.email}</label>

            <span className={styles.error}>{err.email ? err.email : ""}</span>
          </div>
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>Address Line 1</label>
            <label className={styles.pbtfrtxt}>
              {existingData?.address_line_1}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>Address Line 2</label>
            <label className={styles.pbtfrtxt}>
              {existingData?.address_line_2}
            </label>
          </div>{" "}
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>City</label>
            <label className={styles.pbtfrtxt}>{existingData?.city}</label>
          </div>
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>Pincode</label>
            <label className={styles.pbtfrtxt}>{existingData?.pincode}</label>
          </div>
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>Landmark</label>
            <label className={styles.pbtfrtxt}>{existingData?.landmark}</label>
          </div>
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>Dealer LOI </label>
            <label className={styles.ptpfrtxt}>
              {existingData && existingData?.dealer_loi && (
                <button
                  type="button"
                  onClick={() => handleViewPdf(existingData.dealer_loi)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>PAN No.</label>
            <label className={styles.pbtfrtxt}>
              {existingData?.pan_number}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>Uploaded PAN</label>
            <label className={styles.ptpfrtxt}>
              {existingData && existingData?.pan && (
                <button
                  type="button"
                  onClick={() => handleViewPdf(existingData.pan)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>Bank Statement</label>
            <label className={styles.ptpfrtxt}>
              {existingData && existingData?.bank_statement && (
                <button
                  type="button"
                  onClick={() => handleViewPdf(existingData.bank_statement)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>Trade Certificate </label>
            <label className={styles.ptpfrtxt}>
              {existingData && existingData?.trade_certificate && (
                <button
                  type="button"
                  onClick={() => handleViewPdf(existingData.trade_certificate)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>GST Certificate </label>
            <label className={styles.ptpfrtxt}>
              {existingData && existingData?.gst_certificate && (
                <button
                  type="button"
                  onClick={() => handleViewPdf(existingData.gst_certificate)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>Security Cheque 1</label>
            <label className={styles.ptpfrtxt}>
              {existingData && existingData?.security_cheque_1 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.security_cheque_1)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>Security Cheque 2</label>
            <label className={styles.ptpfrtxt}>
              {existingData && existingData?.security_cheque_2 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.security_cheque_2)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>Security Cheque 3</label>
            <label className={styles.ptpfrtxt}>
              {existingData && existingData?.security_cheque_3 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.security_cheque_3)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>Security Cheque 4</label>
            <label className={styles.ptpfrtxt}>
              {existingData && existingData?.security_cheque_4 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData?.security_cheque_4)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>Rent/Lease Agreement </label>
            <label className={styles.ptpfrtxt}>
              {existingData && existingData?.rent_or_lease_agreement && (
                <button
                  type="button"
                  onClick={() =>
                    handleViewPdf(existingData.rent_or_lease_agreement)
                  }
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>Dealer Image</label>
            <label className={styles.ptpfrtxt}>
              {existingData && existingData?.dealer_image_1 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.dealer_image_1)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          {/* <div className={styles.searorinp}>
            <label
              className={styles.ptpfrtxt}
            >
              Dealer Image 2
              {existingData && existingData?.dealer_image_2 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.dealer_image_2)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div> */}
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>Showroom Image</label>
            <label className={styles.ptpfrtxt}>
              {existingData && existingData?.showroom_img_1 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.showroom_img_1)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          {/* <div className={styles.searorinp}>
            <label
              className={styles.ptpfrtxt}
            >
              Showroom Image 2
              {existingData && existingData?.showroom_img_2 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.showroom_img_2)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              className={styles.ptpfrtxt}
            >
              Showroom Image 3
              {existingData && existingData?.showroom_img_3 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.showroom_img_3)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              className={styles.ptpfrtxt}
            >
              Showroom Image 4
              {existingData && existingData?.showroom_img_4 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.showroom_img_4)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div> */}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Dealer Finayo Agreement
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              {existingData && existingData?.dealer_finayo_agreement && (
                <button
                  type="button"
                  onClick={() =>
                    handleViewPdf(existingData.dealer_finayo_agreement)
                  }
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>ITR File</label>
            <label className={styles.ptpfrtxt}>
              {existingData && existingData?.itr_file && (
                <button
                  type="button"
                  onClick={() => handleViewPdf(existingData.itr_file)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          {/* <div className={styles.searorinp}>
            <label
              className={styles.ptpfrtxt}
            >
              Manufacturing License{" "}
              {existingData && existingData?.manufacturing_license && (
                <button
                  type="button"
                  onClick={() =>
                    handleViewPdf(existingData.manufacturing_license)
                  }
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              className={styles.ptpfrtxt}
            >
              ICAT Certificate{" "}
              {existingData && existingData?.icat_certificate && (
                <button
                  type="button"
                  onClick={() => handleViewPdf(existingData.icat_certificate)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div> */}
          <div className={styles.searorinp}>
            <label className={styles.ptpfrtxt}>Utility Bill </label>
            <label className={styles.ptpfrtxt}>
              {existingData && existingData?.utility_bill && (
                <button
                  type="button"
                  onClick={() => handleViewPdf(existingData.utility_bill)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          {existingData?.company_type === "PARTNERSHIP" && (
            <div className={styles.searorinp}>
              <label className={styles.pbtfrtxt}>Partnership Deed </label>
              <label className={styles.pbtfrtxt}>
                {existingData && existingData?.partnership_deed && (
                  <button
                    type="button"
                    onClick={() => handleViewPdf(existingData.partnership_deed)}
                    className={styles.bbtfnvogfde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
          )}
          {existingData?.company_type === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label className={styles.pbtfrtxt}>Balance Sheet</label>
              <label className={styles.pbtfrtxt}>
                {existingData && existingData?.balance_sheet && (
                  <button
                    type="button"
                    onClick={() => handleViewPdf(existingData.balance_sheet)}
                    className={styles.bbtfnvogfde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
          )}{" "}
          {existingData?.company_type === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label className={styles.pbtfrtxt}>MOA</label>
              <label className={styles.pbtfrtxt}>
                {existingData && existingData?.moa && (
                  <button
                    type="button"
                    onClick={() => handleViewPdf(existingData.moa)}
                    className={styles.bbtfnvogfde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
          )}{" "}
          {existingData?.company_type === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label className={styles.pbtfrtxt}>AOA</label>
              <label className={styles.pbtfrtxt}>
                {existingData && existingData?.aoa && (
                  <button
                    type="button"
                    onClick={() => handleViewPdf(existingData.aoa)}
                    className={styles.bbtfnvogfde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
          )}{" "}
          {existingData?.company_type === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label className={styles.pbtfrtxt}>
                Certificate Of Incorporation
              </label>
              <label className={styles.pbtfrtxt}>
                {existingData && existingData?.certificate_of_incorporation && (
                  <button
                    type="button"
                    onClick={() =>
                      handleViewPdf(existingData.certificate_of_incorporation)
                    }
                    className={styles.bbtfnvogfde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
          )}
          {/* {existingData?.company_type === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
               className={styles.pbtfrtxt}
              >
                Factory Address Proof{" "}
                {existingData && existingData?.factory_address_proof && (
                  <button
                    type="button"
                    onClick={() =>
                      handleViewPdf(existingData.factory_address_proof)
                    }
                    className={styles.bbtfnvogfde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
          )} */}
        </div>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            overflowY: "scroll",
          }}
        >
          <button
            onClick={() => setOpen(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 10,
              backgroundColor: "white",
              border: "none",
              cursor: "pointer",
              padding: "5px 10px",
              borderRadius: "5px",
              width: "300px",
            }}
          >
            Close
          </button>

          <iframe
            src={pdfUrl}
            style={{
              width: "100%",
              height: "2000px",
              border: "none",
              pointerEvents: "none",
            }}
          />

          <div style={overlayStyle} />
        </div>
      </Dialog>
    </div>
  );
};

export default DealerDet;
