import React, { useContext, useState } from "react";
import classes from "./Language.module.css";
import Breadcum from "../../Common/Breadcum";
import { AuthContext } from "../../../context/AuthContext";
import { useForm } from "react-hook-form";
import { Backdrop } from "@mui/material";
import AddModal from "./AddModal";
import useCustomMutation from "../../CustomHook/useCustomMutation";
const VarifyItsYou = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const { user, token, setUser, setToken } = useContext(AuthContext);
  const [add, setAdd] = useState(false);
  console.log("user", user);
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const onSubmit = async (data) => {
    let payload;
    payload = {
      mobile: data?.mobile,
    };
    console.log("updatedData", payload);
    updateVpnMutation.mutate(payload);
  };

  const updateVpnMutation = useCustomMutation({
    url: "admin/account-verfication-send-otp",
    method: "post",
    invalidateKey: "langList",
    successCallback: () => {
      addHandle();
    },
  });
  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      <div className={classes.mainBodyContainer}>
        <Breadcum pageName="User Verification" />
        {/* <div className={classes["main-body"]} style={{ padding: "15px" }}>
          <div className={classes.tablco}>
            </div> */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.tableContai}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "400px", // To take full height of the viewport
            padding: "15px",
            background: "#fff",
          }}
        >
          <div style={{ width: "35%", textAlign: "left" }}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
                marginBottom: "3px",
              }}
            >
              Registered Phone Number
            </label>
            <input
              type="number"
              placeholder="Enter Registered Phone Number"
              {...register("mobile", {
                required: true,
                minLength: {
                  value: 10,
                  message: "10 digits Required",
                },
              })}
              className={classes.inpforsearch}
              style={{
                width: "100%",
                borderRadius: "5px",
                height: "25px",
                marginBottom: "6px",
              }}
            />
            {errors.mobile && (
              <span className={classes.error}>This Field is Missing.</span>
            )}{" "}
            <button
              style={{ width: "120px", maxHeight: "30px" }}
              className={classes.addbtn}
              type="submit"
            >
              Verify
            </button>
          </div>
        </form>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <AddModal addHandle={addHandle} />
      </Backdrop>
    </div>
  );
};

export default VarifyItsYou;
