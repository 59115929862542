import React from "react";
import { useForm } from "react-hook-form";
import styles from "./Search.module.css";
import { useToast } from "../../../context/ToastProvder";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
const SearchComp = ({ onSearch }) => {
  const { register, handleSubmit, reset, watch } = useForm();
  // const onSubmit = (data) => {
  //   console.log("searchForm submitted:", data);
  //   onSearch(data);
  // };
  const { showToast } = useToast();
  const onSubmit = (data) => {
    const { start_date, end_date } = data;

    // Check if end date is selected without a start date
    if (end_date && !start_date) {
      showToast("Please Select Start Date First");
      return;
    }
    if (end_date && start_date && end_date < start_date) {
      showToast(
        "The end date field must be a date after or equal to start date."
      );
      return;
    }
    console.log("searchForm submitted:", data);
    onSearch(data);
  };

  const resetHandler = () => {
    reset();
    onSearch(null);
  };
  const [{ data: depatmentList }, { data: userTypeList }] = useQueries({
    queries: [
      {
        queryKey: ["department"],
        queryFn: () =>
          api.get("get-department-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["userType"],
        queryFn: () =>
          api.get("get-user-type-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  const currentDate = new Date().toISOString().split("T")[0];
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: "10px" }}>
        <div className={styles.fourinputasc} style={{ gap: "20px" }}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Start Date
            </label>
            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("start_date")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              End Date
            </label>
            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("end_date")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Max Loan Amount
            </label>
            <input
              type="number"
              className={styles.inpttsc}
              {...register("max_loan_amount")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Min Loan Tenure
            </label>
            <input
              type="number"
              className={styles.inpttsc}
              {...register("min_loan_tenure")}
            />
          </div>
        </div>
        <div
          className={styles.fourinputasc}
          style={{ gap: "20px", marginTop: "10px" }}
        >
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Max Loan Tenure
            </label>
            <input
              type="number"
              className={styles.inpttsc}
              {...register("max_loan_tenure")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Name
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("name")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Product Type
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("product_type")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Model
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("model_unq")}
            />
          </div>
        </div>
        <div
          className={styles.fourinputasc}
          style={{ gap: "20px", marginTop: "10px" }}
        >
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Battery Type
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("battery_type")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Dealer ID
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("dealer_id")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Ex Showroom Price
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("ex_showroom_price")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Loan To Value Ratio
            </label>
            <input
              type="number"
              className={styles.inpttsc}
              {...register("loan_to_value_ratio")}
            />
          </div>
        </div>
        <div
          className={styles.twoinputasc}
          style={{ gap: "20px", marginTop: "10px" }}
        >
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Manufacturer Name
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("manufacture_name")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Interest
            </label>
            <input
              type="number"
              className={styles.inpttsc}
              {...register("interest")}
            />
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
            display: "flex",
            gap: "10px",
            marginBottom: "10px",
            position: "relative",
            bottom: "5px",
          }}
        >
          <button
            style={{
              maxHeight: "30px",
              border: "none",
              color: "#fff",
              background: "#45cb85",
              borderRadius: "5px",
              position: "relative",
              top: "14px",
              height: "25px",
            }}
            type="submit"
          >
            Search
          </button>
          <button
            style={{
              maxHeight: "30px",
              border: "none",
              color: "#fff",
              background: "#f06548",
              borderRadius: "5px",
              position: "relative",
              top: "14px",
              height: "25px",
            }}
            type="button"
            onClick={resetHandler}
          >
            Clear
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchComp;
