import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./Language.module.css";
const StepNine = () => {
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [type, setType] = useState(null);
  const [totalPage, setTotalPage] = useState(2);
  const navigate = useNavigate();
  const handleEditRow = (typee) => {
    setType(typee);
    // navigate("/someotherpage", {
    //   state: { type },
    // });
  };
  console.log({ type });
  const location = useLocation();
  const { val } = location.state || "";
  console.log("val", val);
  return (
    <div className={classes.tablco}>
      <p className={classes.applheader}>Borrower Documents</p>
      <div className={classes.totalcheckconnnforimgsec}>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Image
            <span className={classes.requ}>*</span>
          </label>
          <input
            // placeholder="Enter Name"
            {...register("name", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Aadhar Front<span className={classes.requ}>*</span>
          </label>
          <input
            // placeholder="Enter Name"
            {...register("dob", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.dob && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Aadhar Back<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            PAN Card<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            URC Certificate<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Electricity Bill
            <span className={classes.requ}>*</span>
          </label>
          <input
            // placeholder="Enter Name"
            {...register("name", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Rent Agreement<span className={classes.requ}>*</span>
          </label>
          <input
            // placeholder="Enter Name"
            {...register("dob", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.dob && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Name Declaration<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Driving License<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Signed Cheque 1<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Signed Cheque 2<span className={classes.requ}>*</span>
          </label>
          <input
            // placeholder="Enter Name"
            {...register("name", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Signed Cheque 3<span className={classes.requ}>*</span>
          </label>
          <input
            // placeholder="Enter Name"
            {...register("dob", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.dob && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Signed Cheque 4<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Other Documents 1<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Other Documents 2<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Other Documents 3<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Other Documents 4<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
      </div>
      <hr style={{ border: "0.5px solid #ddd", margin: "20px 0" }} />
      <p className={classes.applheader}>CO-APPLICANT DOCUMENTS</p>
      <div className={classes.totalcheckconnnforimgsec}>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Image<span className={classes.requ}>*</span>
          </label>
          <input
            // placeholder="Enter Name"
            {...register("name", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Aadhar Front<span className={classes.requ}>*</span>
          </label>
          <input
            // placeholder="Enter Name"
            {...register("dob", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.dob && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Aadhar Back<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            PAN Card<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Electricity Bill<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Rent Agreement<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Name Declaration<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
      </div>
      <hr style={{ border: "0.5px solid #ddd", margin: "20px 0" }} />
      <p className={classes.applheader}>GUARANTOR DOCUMENTS </p>
      <div className={classes.totalcheckconnnforimgsec}>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Image<span className={classes.requ}>*</span>
          </label>
          <input
            // placeholder="Enter Name"
            {...register("name", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Aadhar Front<span className={classes.requ}>*</span>
          </label>
          <input
            // placeholder="Enter Name"
            {...register("dob", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.dob && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Aadhar Back<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            PAN Card<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Electricity Bill<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Rent Agreement<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Name Declaration<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("ff", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
      </div>
      <div
        className={classes.buttonasc}
        style={{ marginTop: "10px", textAlign: "left" }}
      >
        {/* {!defaultValue && ( */}
        <button
          type="submit"
          className={classes.Subbtn}
          style={{ width: "80px", marginTop: "10px" }}
        >
          Submit
        </button>
        {/* )} */}
      </div>
    </div>
  );
};

export default StepNine;

// import React, { useState } from "react";
// import classes from "./Language.module.css";
// import { useNavigate } from "react-router-dom";
// import AdditionalSeven from "./AdditionalSeven";
// import Thankyou from "../../../icons/Thnaks.png";
// const SevenEight = ({ setActiveStep }) => {
//   const [show, setShow] = useState(false);
//   const HandleShow = () => {
//     setShow(true);
//   };
//   return (
//     <div
//       className={classes.tablco}
//       // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
//     >
//       <div
//         style={{
//           boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
//           padding: "15px",
//           width: "60%",
//           margin: "0 auto",
//           textAlign: "left",
//           borderRadius: "7px",
//         }}
//       >
//         {/* <img src={Thankyou} style={{ width: "300px" }} /> */}
//         <p className={classes.thnktt}>You Have Selected A Lender</p>
//         <p className={classes.btmthnktt}>
//           Your Lender is One of Finayo's partner.We Work Together To Give You
//           Seamless Service.
//         </p>
//         <div>
//           <p className={classes.bnkNamet}>Lender's Name : Axis Bank</p>
//           <p className={classes.bnkNamett}>Process By Finayo</p>
//           <p className={classes.bnkNamett} style={{ marginTop: "5px" }}>
//             1. Lorem Ipsum is simply dummy text of the printing and typesetting
//             industry.
//           </p>
//         </div>
//         <div style={{ marginTop: "15px" }}>
//           <button
//             type="button"
//             className={classes.Subbtn}
//             style={{
//               width: "80px",
//               background: "#c6131b",
//               marginRight: "15px",
//             }}
//           >
//             Reject
//           </button>
//           <button
//             type="button"
//             className={classes.Subbtn}
//             style={{ width: "80px", background: "#45cb85" }}
//           >
//             Accept
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SevenEight;
