import React, { useState } from "react";
import classes from "./Language.module.css";
import { useNavigate } from "react-router-dom";
import AdditionalSeven from "./AdditionalSeven";
import Thankyou from "../../../icons/Thnaks.png";
const SevenEight = ({ setActiveStep }) => {
  const [show, setShow] = useState(false);
  const HandleShow = () => {
    setShow(true);
  };
  return (
    <div
      className={classes.tablco}
      // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    >
      <div
        style={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          padding: "15px",
          width: "60%",
          margin: "0 auto",
          textAlign: "left",
          borderRadius: "7px",
        }}
      >
        {/* <img src={Thankyou} style={{ width: "300px" }} /> */}
        <p className={classes.thnktt}>You Have Selected A Lender</p>
        <p className={classes.btmthnktt}>
          Your Lender is One of Finayo's partner.We Work Together To Give You
          Seamless Service.
        </p>
        <div>
          <p className={classes.bnkNamet}>Lender's Name : Axis Bank</p>
          <p className={classes.bnkNamett}>Process By Finayo</p>
          <p className={classes.bnkNamett} style={{ marginTop: "5px" }}>
            1. Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
        </div>
        <div style={{ marginTop: "15px" }}>
          <button
            type="button"
            className={classes.Subbtn}
            style={{
              width: "80px",
              background: "#c6131b",
              marginRight: "15px",
            }}
          >
            Reject
          </button>
          <button
            type="button"
            className={classes.Subbtn}
            style={{ width: "80px", background: "#45cb85" }}
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default SevenEight;
