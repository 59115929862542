import React, { useState } from "react";
import classes from "./AccessPermisson.module.css";
import Breadcum from "../../Common/Breadcum";
import { useLocation, useNavigate } from "react-router-dom";
import menuData from "./data.json";
import { useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import useCustomMutation from "../../CustomHook/useCustomMutation";
const AccessPermisson = () => {
  const [selectedPermissions, setSelectedPermissions] = useState({});
  const [upd, setUpd] = useState(false);
  const location = useLocation();
  const { val } = location.state || "";
  console.log("selectedPermissions", selectedPermissions);
  const fetchData = async ({ val }) => {
    // const payload = { page, number_of_records: numberOfRecords, step: step };

    const response = await api.get("admin/get-menu-groups");
    return response.data;
  };

  const DetailsResponse = useQuery({
    queryKey: ["borrowerList", val],
    queryFn: () => fetchData({ val }),
    onSuccess: (data) => {
      console.log("on succ data", data);
    },
  });
  const fetchAccessRoles = async () => {
    const response = await api.get(`admin/get-access-role-by-user-type/${val}`);
    return response.data;
  };
  const { data: accessRoles } = useQuery({
    queryKey: ["accessRoles", val],
    queryFn: fetchAccessRoles,
    onSuccess: (data) => {
      const initialPermissions = {};

      data.data.forEach((module) => {
        module.menu_items.forEach((menuItem) => {
          menuItem.submenus.forEach((submenu) => {
            const { submenu_id, permissions } = submenu;

            // Initialize the submenu permissions if not already present
            if (!initialPermissions[submenu_id]) {
              initialPermissions[submenu_id] = [];
            }

            // Add the permission to the submenu's permissions array
            if (!initialPermissions[submenu_id].includes(permissions)) {
              initialPermissions[submenu_id].push(permissions);
              setUpd(true);
            }
          });
        });
      });

      // Set the selected permissions state
      setSelectedPermissions(initialPermissions);
      // console.log("data for accrole", data);
      // console.log("initialPermissions", initialPermissions);
    },
  });

  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;
  const permissionList = [
    "create",
    "delete",
    "read",
    "update",
    "import",
    "export",
  ];

  const handleCheckboxChange = (subMenuId, permission) => {
    setSelectedPermissions((prev) => {
      const permissions = prev[subMenuId] || [];

      if (permission === "access") {
        // Toggle all permissions
        const allSelected = permissionList.every((perm) =>
          permissions.includes(perm)
        );
        return {
          ...prev,
          [subMenuId]: allSelected ? [] : [...permissionList],
        };
      } else {
        // Toggle individual permission
        return {
          ...prev,
          [subMenuId]: permissions.includes(permission)
            ? permissions.filter((perm) => perm !== permission)
            : [...permissions, permission],
        };
      }
    });
  };

  const handleSubmit = () => {
    const payload = {
      user_type_id: val,
      submenus: Object.entries(selectedPermissions).map(
        ([subMenuId, permissions]) => ({
          id: parseInt(subMenuId),
          permissions,
        })
      ),
    };
    updateVpnMutation.mutate(payload);
    console.log("Payload:", payload);
  };
  // const updateVpnMutation = useCustomMutation({
  //   url:
  //     accessRoles?.length > 0
  //       ? "admin/update-access-role"
  //       : "admin/store-access-role",
  //   method: accessRoles?.length > 0 ? "put" : "post",
  //   invalidateKey: "bankDetailsList",
  //   successCallback: () => {
  //     // Additional success handling logic if needed
  //   },
  // });
  const updateVpnMutation = useCustomMutation({
    url: upd ? "admin/update-access-role" : "admin/store-access-role",
    method: upd ? "put" : "post",
    invalidateKey: "bankDetailsList",
    successCallback: () => {
      window.location.reload();
      // Additional success handling logic if needed
    },
  });
  const [perm, setPerm] = useState([]);
  const fetchPermissions = async () => {
    const response = await api.get("admin/get-sub-menu-permissions/1");
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["submenuPermissions"],
    queryFn: fetchPermissions,
    onSuccess: (data) => {
      setPerm(data.data);
    },
  });
  const { data: permissionsData } = PermissionsResponse;
  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      <div className={classes.mainBodyContainer}>
        <Breadcum pageName="Access Permission" />
        <div
          className={classes["main-body"]}
          style={{ padding: "15px", textAlign: "left" }}
        >
          <div className={classes.tablco}>
            {/* <p className={classes.toptext}>
              Access for <span style={{ color: "#25a529" }}>Manager</span>
            </p> */}
            <div className={classes.btnco}>
              {dashboardList?.data?.map((menu, menuIndex) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // gap: "10px",
                    // paddingBottom: "10px",
                  }}
                >
                  {menu?.menu_items?.length > 0 && (
                    <p
                      style={{
                        padding: "10px 0",
                        color: "#f06548",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      {menu?.name}
                    </p>
                  )}

                  <div className={classes.totalcheckcon}>
                    {menu?.menu_items?.map((subMenu, subMenuIndex) => (
                      <>
                        {subMenu?.sub_menu_items?.map(
                          (subSubMenu, subSubMenuIndex) => (
                            <div className={classes.checkboxcont}>
                              <div className={classes.checkboxheader}>
                                {/* <input
                                  type="checkbox"
                                  checked={
                                    selectedPermissions[subSubMenu.id]
                                      ? selectedPermissions[subSubMenu.id]
                                          .length === permissionList.length
                                      : false
                                  }
                                  onChange={() =>
                                    handleCheckboxChange(
                                      subSubMenu.id,
                                      "access"
                                    )
                                  }
                                /> */}
                                <input
                                  type="checkbox"
                                  checked={
                                    selectedPermissions[subSubMenu.id]
                                      ? permissionList.some((perm) =>
                                          selectedPermissions[
                                            subSubMenu.id
                                          ].includes(perm)
                                        )
                                      : false
                                  }
                                  onChange={() =>
                                    handleCheckboxChange(
                                      subSubMenu.id,
                                      "access"
                                    )
                                  }
                                />
                                <p>
                                  {subMenu?.name}/{subSubMenu?.name}
                                </p>
                              </div>
                              <div className={classes.checksubmenucon}>
                                {permissionList.map((perm) => (
                                  <div
                                    className={classes.submenucheck}
                                    key={`${subSubMenu.id}-${perm}`}
                                  >
                                    <input
                                      type="checkbox"
                                      onChange={() =>
                                        handleCheckboxChange(
                                          subSubMenu.id,
                                          perm.toLowerCase()
                                        )
                                      }
                                      // checked={selectedPermissions[
                                      //   subSubMenu.id
                                      // ]?.includes(perm)}
                                      checked={
                                        selectedPermissions[subSubMenu.id]
                                          ? selectedPermissions[
                                              subSubMenu.id
                                            ].includes(perm)
                                          : false
                                      }
                                    />
                                    <p>{perm}</p>
                                  </div>
                                ))}
                                {/* <div
                                  className={classes.submenucheck}
                                  key={subMenuIndex}
                                >
                                  <input type="checkbox" />
                                  <p>Create</p>
                                </div>
                                <div
                                  className={classes.submenucheck}
                                  key={subMenuIndex}
                                >
                                  <input type="checkbox" />
                                  <p>Delete</p>
                                </div>

                                <div
                                  className={classes.submenucheck}
                                  key={subMenuIndex}
                                >
                                  <input type="checkbox" />
                                  <p>Read</p>
                                </div>
                                <div
                                  className={classes.submenucheck}
                                  key={subMenuIndex}
                                >
                                  <input type="checkbox" />
                                  <p>Update</p>
                                </div>
                                <div
                                  className={classes.submenucheck}
                                  key={subMenuIndex}
                                >
                                  <input type="checkbox" />
                                  <p>Import</p>
                                </div>
                                <div
                                  className={classes.submenucheck}
                                  key={subMenuIndex}
                                >
                                  <input type="checkbox" />
                                  <p>Export</p>
                                </div> */}
                              </div>
                            </div>
                          )
                        )}
                      </>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            {perm?.includes("update") && (
              <button onClick={handleSubmit} className={classes.Subbtn}>
                Submit
              </button>
            )}
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default AccessPermisson;
