import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./Language.module.css";
import AdditionalElev from "./AdditionalElev";
const StepElev = () => {
  const [show, setShow] = useState(false);
  const HandleShow = () => {
    setShow(true);
  };
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [type, setType] = useState(null);
  const [totalPage, setTotalPage] = useState(2);
  const navigate = useNavigate();
  const handleEditRow = (typee) => {
    setType(typee);
    // navigate("/someotherpage", {
    //   state: { type },
    // });
  };
  console.log({ type });
  const location = useLocation();
  const { val } = location.state || "";
  console.log("val", val);
  return (
    <div>
      {!show && (
        <div className={classes.tablco}>
          <p className={classes.applheader}>FIELD INSPECTION REQUEST</p>
          <div className={classes.totalcheckconnn}>
            <div className={classes.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                Name<span className={classes.requ}>*</span>
              </label>
              <input
                // placeholder="Enter Name"
                {...register("name", { required: true })}
                type="text"
                className={classes.inputstst}
              />

              {errors.name && (
                <span className={classes.error}>This Field is Missing.</span>
              )}
            </div>

            <div className={classes.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                Mobile Number<span className={classes.requ}>*</span>
              </label>
              <input
                // placeholder="Enter Mobile Number"
                {...register("name", { required: true })}
                type="number"
                className={classes.inputstst}
              />

              {errors.name && (
                <span className={classes.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          <div
            className={classes.buttonasc}
            style={{ marginTop: "10px", textAlign: "left" }}
          >
            {/* {!defaultValue && ( */}
            <button
              type="submit"
              className={classes.Subbtn}
              onClick={HandleShow}
              style={{ width: "80px" }}
            >
              Submit
            </button>
          </div>
        </div>
      )}
      {show && <AdditionalElev />}
    </div>
  );
};

export default StepElev;
