import React, { useState } from "react";
import classes from "./Language.module.css";
import Breadcum from "../../Common/Breadcum";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import { MdOutlineNavigateNext } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import DealerForm from "./DealerForm";
import { useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import Fom from "./Fom";
import MyForm from "../EditBorrowerDocuments/Fom";

const EditGuarantorDocuments = () => {
  const location = useLocation();
  const [dtt, setDtt] = useState(1);
  const { borrowerId } = location.state || {};
  console.log("borrowerId", borrowerId);
  const fetchData = async () => {
    // const response = await api.get(
    //   `admin/get-documents-by-borrower/${borrowerId}`
    // );
    const response = await api.get(`get-borrower-details/${borrowerId}`);
    return response.data;
  };
  //FIN-0144
  //FIN-0173

  const DetailsResponse = useQuery({
    queryKey: ["bankDetailsList", borrowerId, dtt],
    queryFn: () => fetchData(borrowerId),
    onSuccess: (data) => {
      // data?.allBankDetailsList.sort((a, b) =>
      //   a.bank_name.localeCompare(b.bank_name)
      // );
    },
  });
  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;
  console.log("dtt", dtt);
  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      <div className={classes.mainBodyContainer}>
        <Breadcum pageName="Edit Documents" />
        <div className={classes["main-body"]} style={{ padding: "15px" }}>
          <div className={classes.tablco}>
            {/* <MyForm
              // defaultValue={val}
              defaultValue={dashboardList?.data || []}
              doc={dashboardList?.data?.documents}
            /> */}
            {/* <Fom
              // defaultValue={val}
              defaultValue={dashboardList?.data || []}
              doc={dashboardList?.data?.documents}
            /> */}
            <DealerForm
              // defaultValue={val}
              defaultValue={dashboardList?.data || []}
              doc={dashboardList?.data?.documents}
              setDtt={setDtt}
              dtt={dtt}
              borrowerId={borrowerId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditGuarantorDocuments;
