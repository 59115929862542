import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./Language.module.css";
import AdditionalElev from "./AdditionalElev";
import AdditionalTwel from "./AdditionalTwel";
const StepFifthee = () => {
  const [show, setShow] = useState(false);
  const HandleShow = () => {
    setShow(true);
  };
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [type, setType] = useState(null);
  const [totalPage, setTotalPage] = useState(2);
  const navigate = useNavigate();
  const handleEditRow = (typee) => {
    setType(typee);
    // navigate("/someotherpage", {
    //   state: { type },
    // });
  };
  console.log({ type });
  const location = useLocation();
  const { val } = location.state || "";
  console.log("val", val);
  return (
    <div>
      <div className={classes.tablco}>
        <p className={classes.applheader}>GPS LOCATION IOT DEVICE</p>
        <div className={classes.totalcheckconnn}>
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Vehicle ID<span className={classes.requ}>*</span>
            </label>
            <input
              // placeholder="Enter Name"
              {...register("name", { required: true })}
              type="text"
              className={classes.inputstst}
            />
            {errors.name && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
            <span className={classes.error}>NOT ACTIVATED</span>
          </div>
        </div>
        <div
          className={classes.buttonasc}
          style={{ marginTop: "10px", textAlign: "left" }}
        >
          {/* {!defaultValue && ( */}
          <button
            type="submit"
            className={classes.Subbtn}
            onClick={HandleShow}
            style={{ width: "80px", marginRight: "12px" }}
          >
            Activate
          </button>
          <button
            type="submit"
            className={classes.Subbtn}
            style={{ width: "80px", background: "rgb(198, 19, 27)" }}
          >
            Skip
          </button>
        </div>
      </div>
    </div>
  );
};

export default StepFifthee;
