// import React, { useEffect, useState } from "react";
// import styles from "./Modal.module.css";
// import { style } from "@mui/system";
// import { useForm } from "react-hook-form";
// import close from "./../../../icons/close.png";
// import sampleimg from "./../../../icons/sampleimg.png";
// import ZoomImg from "./ZoomImg";
// import { Backdrop } from "@mui/material";
// const GenralDoc = ({ addHandle, defaultValue, borrowerId, doc, type }) => {
//   const [bimg, setBimg] = useState(false);
//   const [zoomImgUrl, setZoomImgUrl] = useState(null);

//   const imgHandle = (url) => {
//     setZoomImgUrl(url);
//     setBimg((val) => !val);
//   };
//   const getFullUrl = (path) =>
//     path ? `https://insta.finayo.tech${path}` : null;

//   const isPdf = (path) => path?.endsWith(".pdf");

//   const renderImages = (imagePaths) => {
//     return imagePaths
//       .split(",")
//       .map((path, index) => (
//         <img
//           key={index}
//           src={getFullUrl(path)}
//           style={{ width: "150px", height: "100px" }}
//           alt={`Signed Cheque ${index + 1}`}
//           onClick={() => imgHandle(getFullUrl(path))}
//         />
//       ));
//   };
//   console.log("doc", doc);
//   return (
//     <form
//       style={{ minWidth: "400px", padding: "0 10px " }}
//       className={styles.modalcontainerr}
//       onClick={(event) => event.stopPropagation()}
//     >
//       <Backdrop
//         sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
//         open={bimg}
//         onClick={imgHandle}
//       >
//         <ZoomImg
//           addHandle={imgHandle}
//           zoomImgUrl={zoomImgUrl}
//           borrowerId={borrowerId}
//         />
//       </Backdrop>
//       <div className={styles.modalHeader}>
//         <h3 className={styles.modalheading}>General Documents </h3>
//         <img
//           onClick={addHandle}
//           src={close}
//           style={{
//             width: "11px",
//             position: "relative",
//             top: "3px",
//             cursor: "pointer",
//           }}
//         />
//       </div>
//       <div
//         className={styles.modalbody}
//         style={{
//           borderBotom: "1px solid #red",
//           display: "flex",
//           gap: "10px",
//           overflowX: "auto",
//           width: "500px",
//         }}
//       >
//         <div className={styles.modalform}>
//           {type === "General" && doc.vehicle_image && (
//             <p
//               style={{
//                 color: "#495057",
//                 fontSize: "12px",
//                 fontWeight: "600",
//                 marginBottom: "10px",
//               }}
//             >
//               Vehicle Image
//             </p>
//           )}
//           <div style={{ display: "flex", gap: "10px" }}>
//             {" "}
//             {type === "General" && doc.vehicle_image && (
//               <img
//                 src={getFullUrl(doc.vehicle_image)}
//                 style={{ width: "150px", height: "100px" }}
//                 alt="Vehicle Image"
//                 onClick={() => imgHandle(getFullUrl(doc?.vehicle_image))}
//               />
//             )}
//           </div>
//         </div>
//         {type === "General" && doc.insurance && (
//           <div className={styles.modalform}>
//             <p
//               style={{
//                 color: "#495057",
//                 fontSize: "12px",
//                 fontWeight: "600",
//                 marginBottom: "10px",
//               }}
//             >
//               Insurance
//             </p>
//             <div style={{ display: "flex", gap: "10px" }}>
//               {" "}
//               <img
//                 src={getFullUrl(doc.insurance)}
//                 style={{ width: "150px", height: "100px" }}
//                 alt="Insurance"
//                 onClick={() => imgHandle(getFullUrl(doc?.insurance))}
//               />
//             </div>
//           </div>
//         )}
//         {type === "General" && doc.invoice && (
//           <div className={styles.modalform}>
//             <p
//               style={{
//                 color: "#495057",
//                 fontSize: "12px",
//                 fontWeight: "600",
//                 marginBottom: "10px",
//               }}
//             >
//               Invoice
//             </p>
//             <div style={{ display: "flex", gap: "10px" }}>
//               {" "}
//               <img
//                 src={getFullUrl(doc.invoice)}
//                 style={{ width: "150px", height: "100px" }}
//                 alt="Invoice"
//                 onClick={() => imgHandle(getFullUrl(doc?.invoice))}
//               />
//             </div>
//           </div>
//         )}
//         {type === "General" && doc.vehicle_signed_cheque && (
//           <div className={styles.modalform}>
//             <p
//               style={{
//                 color: "#495057",
//                 fontSize: "12px",
//                 fontWeight: "600",
//                 marginBottom: "10px",
//               }}
//             >
//               Signed Cheque
//             </p>

//             <div style={{ display: "flex", gap: "10px" }}>
//               {" "}
//               {/* <img
//                 src={getFullUrl(doc.vehicle_signed_cheque)}
//                 style={{ width: "150px", height: "100px" }}
//                 alt="Signed Cheque"
//                 onClick={() =>
//                   imgHandle(getFullUrl(doc?.vehicle_signed_cheque))
//                 }
//               /> */}
//               {renderImages(doc.vehicle_signed_cheque)}
//             </div>
//           </div>
//         )}
//         {type === "General" && doc.warrant_document && (
//           <div
//             className={styles.modalform}
//             style={{ marginTop: "10px", padding: "10px" }}
//           >
//             <p
//               style={{
//                 color: "#495057",
//                 fontSize: "12px",
//                 fontWeight: "600",
//                 marginBottom: "10px",
//               }}
//             >
//               Warranty Documents
//             </p>
//             <div style={{ display: "flex", gap: "10px" }}>
//               <img
//                 src={getFullUrl(doc.warrant_document)}
//                 style={{ width: "150px", height: "100px" }}
//                 alt="Warranty Documents"
//                 onClick={() => imgHandle(getFullUrl(doc?.warrant_document))}
//               />
//             </div>
//           </div>
//         )}
//         {type === "General" && doc.vehicle_quotation && (
//           <div
//             className={styles.modalform}
//             style={{ marginTop: "10px", padding: "10px" }}
//           >
//             <p
//               style={{
//                 color: "#495057",
//                 fontSize: "12px",
//                 fontWeight: "600",
//                 marginBottom: "10px",
//               }}
//             >
//               Vehicle Quotation
//             </p>
//             <div style={{ display: "flex", gap: "10px" }}>
//               <img
//                 src={getFullUrl(doc.vehicle_quotation)}
//                 style={{ width: "150px", height: "100px" }}
//                 alt="Vehicle Quotation"
//                 onClick={() => imgHandle(getFullUrl(doc?.vehicle_quotation))}
//               />
//             </div>
//           </div>
//         )}
//         {type === "General" && doc.rto_tax_receipt && (
//           <div
//             className={styles.modalform}
//             // style={{ marginTop: "10px", padding: "10px" }}
//           >
//             <p
//               style={{
//                 color: "#495057",
//                 fontSize: "12px",
//                 fontWeight: "600",
//                 marginBottom: "10px",
//               }}
//             >
//               RTO Tax Receipt
//             </p>
//             <div style={{ display: "flex", gap: "10px" }}>
//               <img
//                 src={getFullUrl(doc.rto_tax_receipt)}
//                 style={{ width: "150px", height: "100px" }}
//                 alt="Rto Tax"
//                 onClick={() => imgHandle(getFullUrl(doc?.rto_tax_receipt))}
//               />
//             </div>
//           </div>
//         )}
//         {type === "General" && doc.rto_document && (
//           <div
//             className={styles.modalform}
//             // style={{ marginTop: "10px", padding: "10px" }}
//           >
//             <p
//               style={{
//                 color: "#495057",
//                 fontSize: "12px",
//                 fontWeight: "600",
//                 marginBottom: "10px",
//               }}
//             >
//               RTO Documents
//             </p>
//             <div style={{ display: "flex", gap: "10px" }}>
//               <img
//                 src={getFullUrl(doc.rto_document)}
//                 style={{ width: "150px", height: "100px" }}
//                 alt="Rto Documents"
//                 onClick={() => imgHandle(getFullUrl(doc?.rto_document))}
//               />
//             </div>
//           </div>
//         )}
//       </div>

//       <div style={{ padding: "10px 15px" }}>
//         <div style={{ display: "flex", justifyContent: "space-between" }}>
//           <p></p>
//           <div style={{ display: "flex", gap: "10px" }}></div>
//         </div>
//       </div>
//     </form>
//   );
// };

// export default GenralDoc;
import React, { useEffect, useState } from "react";
import styles from "./Modal.module.css";
import { Backdrop, Button } from "@mui/material";
import close from "./../../../icons/close.png";
import ZoomImg from "./ZoomImg";
const GenralDoc = ({ addHandle, defaultValue, borrowerId, doc, type }) => {
  const [bimg, setBimg] = useState(false);
  const [zoomImgUrl, setZoomImgUrl] = useState(null);

  const imgHandle = (url) => {
    setZoomImgUrl(url);
    setBimg((val) => !val);
  };

  const getFullUrl = (path) =>
    path ? `https://insta.finayo.tech${path}` : null;

  // const isPdf = (path) => path?.endsWith(".pdf");
  const isPdf = (path) => typeof path === "string" && path.endsWith(".pdf");

  const renderImages = (imagePaths) => {
    return imagePaths
      .split(",")
      .map((path, index) => (
        <img
          key={index}
          src={getFullUrl(path)}
          style={{ width: "150px", height: "100px" }}
          alt={`Signed Cheque ${index + 1}`}
          onClick={() => imgHandle(getFullUrl(path))}
        />
      ));
  };

  const renderDocument = (label, path) => {
    return (
      <div className={styles.modalform}>
        <p
          style={{
            color: "#495057",
            fontSize: "12px",
            fontWeight: "600",
            marginBottom: "10px",
          }}
        >
          {label}
        </p>
        <div style={{ display: "flex", gap: "10px" }}>
          {isPdf(path) ? (
            <Button
              variant="contained"
              color="primary"
              style={{
                maxHeight: "24px",
                width: "125px",
                background: "#f06548",
              }}
              // onClick={() => window.open(getFullUrl(path), "_blank")}
              onClick={() => imgHandle(getFullUrl(path))}
            >
              View PDF
            </Button>
          ) : (
            <img
              src={getFullUrl(path)}
              style={{ width: "150px", height: "100px" }}
              alt={label}
              onClick={() => imgHandle(getFullUrl(path))}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <form
      style={{ minWidth: "400px", padding: "0 10px " }}
      className={styles.modalcontainerr}
      onClick={(event) => event.stopPropagation()}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={bimg}
        onClick={imgHandle}
      >
        <ZoomImg
          addHandle={imgHandle}
          zoomImgUrl={zoomImgUrl}
          borrowerId={borrowerId}
        />
      </Backdrop>
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>General Documents</h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{
          borderBotom: "1px solid #red",
          display: "flex",
          gap: "10px",
          overflowX: "auto",
          width: "500px",
        }}
      >
        {type === "General" &&
          doc.vehicle_image &&
          renderDocument("Vehicle Image", doc.vehicle_image)}
        {type === "General" &&
          doc.insurance &&
          renderDocument("Insurance", doc.insurance)}
        {type === "General" &&
          doc.invoice &&
          renderDocument("Invoice", doc.invoice)}
        {type === "General" && doc.vehicle_signed_cheque && (
          <div className={styles.modalform}>
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Signed Cheques
            </p>
            <div style={{ display: "flex", gap: "10px" }}>
              {renderImages(doc.vehicle_signed_cheque)}
            </div>
          </div>
        )}
        {type === "General" &&
          doc.warrant_document &&
          renderDocument("Warranty Documents", doc.warrant_document)}
        {type === "General" &&
          doc.vehicle_quotation &&
          renderDocument("Vehicle Quotation", doc.vehicle_quotation)}
        {type === "General" &&
          doc.rto_tax_receipt &&
          renderDocument("RTO Tax Receipt", doc.rto_tax_receipt)}
        {type === "General" &&
          doc.chasis_number &&
          renderDocument("Chassis No", doc.chasis_number)}
        {type === "General" &&
          doc.battery_image &&
          renderDocument("Battery Image", doc.battery_image)}
        {type === "General" &&
          doc.motor_controller &&
          renderDocument("Motor Controller", doc.motor_controller)}
        {type === "General" &&
          doc.registration_certificate &&
          renderDocument(
            "Registration Certificate",
            doc.registration_certificate
          )}
      </div>
    </form>
  );
};

export default GenralDoc;
