import React, { useState } from "react";
import classes from "./Language.module.css";
import { useNavigate } from "react-router-dom";
import scooty from "../../../icons/motorcycle.png";
import rickshaw from "../../../icons/rickshaw.png";
import battery from "../../../icons/battery.png";
import auto from "../../../icons/tuk-tuk.png";
const Step1 = ({ setActiveStep }) => {
  const [type, setType] = useState(1);
  const [totalPage, setTotalPage] = useState(2);
  const navigate = useNavigate();
  const handleEditRow = (typee) => {
    // setActiveStep(2);
    setType(typee);
    // navigate("/someotherpage", {
    //   state: { type },
    // });
  };
  console.log({ type });
  return (
    <div className={classes.tablco}>
      <div className={classes.totalcheckconnntd}>
        <div className={classes.applylnx} onClick={() => handleEditRow("L3")}>
          <p className={classes.applyluptxt}>E-Reckshaw (L3)</p>
          <div className={classes.applylnlrdv}>
            <p className={classes.applylnlrdvtxt}>Apply Loan</p>
            <div>
              {/* <MdElectricRickshaw size="40px" /> */}
              <img src={auto} style={{ width: "40px" }} />
            </div>
          </div>
        </div>
        <div className={classes.applylnx} onClick={() => handleEditRow("L5")}>
          <p className={classes.applyluptxt}>E-Auto (L5)</p>
          <div className={classes.applylnlrdv}>
            <p className={classes.applylnlrdvtxt}>Apply Loan</p>
            <div>
              <img src={rickshaw} style={{ width: "45px" }} />
            </div>
          </div>
        </div>
        <div className={classes.applylnx} onClick={() => handleEditRow("L2")}>
          <p className={classes.applyluptxt}>E-Two Wheeler</p>
          <div className={classes.applylnlrdv}>
            <p className={classes.applylnlrdvtxt}>Apply Loan</p>
            <div>
              <img src={scooty} style={{ width: "45px" }} />
            </div>
          </div>
        </div>
        <div className={classes.applylnx} onClick={() => handleEditRow("Bat")}>
          <p className={classes.applyluptxt}>Battery</p>
          <div className={classes.applylnlrdv}>
            <p className={classes.applylnlrdvtxt}>Apply Loan</p>
            <div>
              <img src={battery} style={{ width: "40px" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
