import React, { useState, useEffect } from "react";
import styles from "./MonthBtn.module.css";
import { TbHexagons } from "react-icons/tb";
import { BiCylinder } from "react-icons/bi";
import { useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
const BottomDetails = () => {
  const applicationData = [
    {
      title: "Loan Pipeline",
      progress: "In Progress",
      value: "N/A",
      color: "#ffbe0b",
    },
    {
      title: "Pending Applications",
      progress: "In Approved",
      value: "N/A",
      color: "#45cb85",
    },
    {
      title: "Approved Applications",
      progress: "Rejected",
      value: "N/A",
      color: "#f06548",
    },
    {
      title: "Rejected Applications",
      progress: "Disbursed",
      value: "N/A",
      color: "#25a546",
    },
  ];
  const fetchData = async (searchData) => {
    const payload = {};

    if (searchData) {
      payload.bankId = searchData.bankId || "";
    }

    const response = await api.get("get-yearly-count");
    return response.data;
  };

  const DetailsResponse = useQuery({
    queryKey: ["yearCount"],
    queryFn: () => fetchData(),
    onSuccess: (data) => {
      // data?.allBankDetailsList.sort((a, b) =>
      //   a.bank_name.localeCompare(b.bank_name)
      // );
    },
  });
  const { isLoading, isFetching, data: yearCount } = DetailsResponse;
  console.log("yearCount", yearCount);
  return (
    <div style={{ marginTop: "20px", display: "grid" }}>
      <div className={styles.monthBoxes}>
        {/* {applicationData.map((app, index) => ( */}
        <div className={styles.ttldv}>
          <div className={styles.textconfor}>
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              Loan Pipeline
            </p>
            <p
              style={{
                color: "#878a99",
                fontSize: "11px",
                paddingTop: "1px",
              }}
            >
              In Progress
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {yearCount?.loan_pipeline}
              </p>
              <div
                style={{ background: "#ffbe0b" }}
                className={styles.icocontforbt}
              >
                <BiCylinder size={22} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv}>
          <div className={styles.textconfor}>
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              Pending Applications
            </p>
            <p
              style={{
                color: "#878a99",
                fontSize: "11px",
                paddingTop: "1px",
              }}
            >
              In Approved
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                N/A
              </p>
              <div
                style={{ background: "#45cb85" }}
                className={styles.icocontforbt}
              >
                <BiCylinder size={22} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv}>
          <div className={styles.textconfor}>
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              Approved Applications
            </p>
            <p
              style={{
                color: "#878a99",
                fontSize: "11px",
                paddingTop: "1px",
              }}
            >
              Rejected
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                N/A
              </p>
              <div
                style={{ background: "#f06548" }}
                className={styles.icocontforbt}
              >
                <BiCylinder size={22} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv}>
          <div className={styles.textconfor}>
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              Rejected Applications
            </p>
            <p
              style={{
                color: "#878a99",
                fontSize: "11px",
                paddingTop: "1px",
              }}
            >
              Disbursed
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {yearCount?.rejected_application}
              </p>
              <div
                style={{ background: "#25a546" }}
                className={styles.icocontforbt}
              >
                <BiCylinder size={22} />
              </div>
            </div>
          </div>
        </div>
        {/* ))} */}
      </div>
    </div>
  );
};

export default BottomDetails;
