import React, { useState } from "react";
import { Stepper, Step, StepLabel, Button, Box } from "@mui/material";
import SelectType from "./Step1";
import Breadcum from "../../Common/Breadcum";
import classes from "./Language.module.css";
import Step2 from "./Step2";
import ThirdStep from "./ThirdStep";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import StepSix from "./StepSix";
import SevenStep from "./SevenStep";
import SevenEight from "./SevenEight";
import StepNine from "./StepNine";
import StepTen from "./StepTen";
import StepElev from "./StepElev";
import StepTwel from "./StepTwel";
import ThirteenStep from "./ThirteenStep";
import StepFoutte from "./StepFoutte";
import Step1 from "./Step1";
import StepFifthee from "./StepFifthee";

const Step3 = () => <div>Step 3 Content</div>;
const steps = [
  "Step 1: ",
  "Step 2:",
  "Step 3: ",
  "Step 4:",
  "Step 5:",
  "Step 6:",
  "Step 7:",
  "Step 8:",
  "Step 9:",
  "Step 10:",
  "Step 11:",
  "Step 12:",
  "Step 13:",
  "Step 14:",
  "Step 15:",
];

const ApplyLoan = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipCoApp, setSkipCoApp] = useState(false);
  const handleStepClick = (step) => {
    setActiveStep(step);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Step1 setActiveStep={setActiveStep} />;
      case 1:
        return <Step2 />;

      case 2:
        return <ThirdStep />;
      case 3:
        return <StepFour />;
      case 4:
        return (
          <StepFive
            skipCoApp={skipCoApp}
            setSkipCoApp={setSkipCoApp}
            setActiveStep={setActiveStep}
          />
        );
      case 5:
        return <StepSix />;
      case 6:
        return <SevenStep />;
      case 7:
        return <SevenEight />;
      case 8:
        return <StepNine />;
      case 9:
        return <StepTen />;
      case 10:
        return <StepElev />;
      case 11:
        return <StepTwel />;
      case 12:
        return <ThirteenStep />;
      case 13:
        return <StepFoutte />;
      case 14:
        return <StepFifthee />;
      default:
        return "Unknown Step";
    }
  };

  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      <Breadcum pageName="Apply Loan" />
      <div className={classes.mainBodyContainer}>
        <div
          className={classes["main-body"]}
          style={{ padding: "15px", background: "#fff" }}
        >
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={index} onClick={() => handleStepClick(index)}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Box mt={2}>{getStepContent(activeStep)}</Box>

          {/* <Box mt={2} display="flex" justifyContent="space-between">
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="contained"
            >
              Back
            </Button>
            <Button
              disabled={activeStep === steps.length - 1}
              onClick={handleNext}
              variant="contained"
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box> */}
        </div>
      </div>
    </div>
  );
};

export default ApplyLoan;
