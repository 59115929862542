import React, { useState } from "react";
import classes from "./Language.module.css";
import { RxCheckCircled, RxCrossCircled } from "react-icons/rx";

const AdditionalElev = () => {
  const [type, setType] = useState(1);
  const handleEditRow = (typee) => {
    // setActiveStep(2);
    setType(typee);
    // navigate("/someotherpage", {
    //   state: { type },
    // });
  };
  return (
    <div>
      {/* <p className={classes.applheader}>FIELD INSPECTION REQUEST STATUS</p> */}
      <div className={classes.totalcheckconnn}>
        {/* start */}
        <div
          className={classes.applylonffer}
          onClick={() => handleEditRow("L3")}
        >
          <p
            style={{ padding: "6px", fontSize: "13px" }}
            className={classes.applyluptxt}
          >
            FIELD INSPECTION REQUEST STATUS
          </p>
          <div className={classes.divfrtheredr}>
            <div className={classes.notAppre}>
              {/* <RxCheckCircled size="20" /> */}
              <RxCrossCircled size="25" />
            </div>
            <div className={classes.applylonfthn}>
              <div className={classes.divinffdffg}>
                <p className={classes.txtfoofl}>Name</p>
                <p className={classes.txtfooflbtoneffs}>Surjava</p>
              </div>
              <div className={classes.divinffdffg}>
                <p className={classes.txtfoofl}>Mobile</p>
                <p className={classes.txtfooflbtoneffs}>XXXXXXXXXX</p>
              </div>
            </div>
          </div>
        </div>
        {/* emd */}
      </div>
      <div
        className={classes.buttonasc}
        style={{ marginTop: "10px", textAlign: "left" }}
      >
        {/* {!defaultValue && ( */}
        <button
          type="submit"
          className={classes.Subbtn}
          style={{
            width: "120px",
            background: "rgb(69, 203, 133)",
            marginRight: "12px",
          }}
        >
          Check Status
        </button>
        <button
          type="submit"
          className={classes.Subbtn}
          style={{ width: "120px", background: "rgb(198, 19, 27)" }}
        >
          Skip
        </button>
      </div>
    </div>
  );
};

export default AdditionalElev;
