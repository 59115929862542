import React, { useState } from "react";
import classes from "./Language.module.css";

const AdditionalSeven = () => {
  const [type, setType] = useState(1);
  const handleEditRow = (typee) => {
    // setActiveStep(2);
    setType(typee);
    // navigate("/someotherpage", {
    //   state: { type },
    // });
  };
  return (
    <div className={classes.totalcheckconnn}>
      {/* start */}
      <div className={classes.applylonffer} onClick={() => handleEditRow("L3")}>
        <p
          style={{ padding: "6px", fontSize: "13px" }}
          className={classes.applyluptxt}
        >
          Offer 1
        </p>
        <div className={classes.applylonffergg}>
          <div className={classes.divinffe}>
            <p className={classes.txtfoofl}>Loan Amount</p>
            <p className={classes.txtfooflbtone}>₹120000</p>
          </div>
          <div className={classes.divinffe}>
            <p className={classes.txtfoofl}>Rate of Interest</p>
            <p className={classes.txtfooflbtone}>17.5%</p>
          </div>
        </div>
        <div className={classes.applylonffergg}>
          <div className={classes.divinffe}>
            <p className={classes.txtfoofl}>Terms</p>
            <p className={classes.txtfooflbtone}>16 Month</p>
          </div>
          <div className={classes.divinffe}>
            <p className={classes.txtfoofl}>EMI per Month</p>
            <p className={classes.txtfooflbtone}>₹8463.31</p>
          </div>
        </div>
        <button className={classes.acptbtn}>Accept</button>
      </div>
      {/* emd */}
      {/* start */}
      <div className={classes.applylonffer} onClick={() => handleEditRow("L3")}>
        <p
          style={{ padding: "6px", fontSize: "13px" }}
          className={classes.applyluptxt}
        >
          Offer 2
        </p>
        <div className={classes.applylonffergg}>
          <div className={classes.divinffe}>
            <p className={classes.txtfoofl}>Loan Amount</p>
            <p className={classes.txtfooflbtone}>₹120000</p>
          </div>
          <div className={classes.divinffe}>
            <p className={classes.txtfoofl}>Rate of Interest</p>
            <p className={classes.txtfooflbtone}>17.5%</p>
          </div>
        </div>
        <div className={classes.applylonffergg}>
          <div className={classes.divinffe}>
            <p className={classes.txtfoofl}>Terms</p>
            <p className={classes.txtfooflbtone}>16 Month</p>
          </div>
          <div className={classes.divinffe}>
            <p className={classes.txtfoofl}>EMI per Month</p>
            <p className={classes.txtfooflbtone}>₹8463.31</p>
          </div>
        </div>
        <button className={classes.acptbtn}>Accept</button>
      </div>
      {/* end */}
      {/* start */}
      <div className={classes.applylonffer} onClick={() => handleEditRow("L3")}>
        <p
          style={{ padding: "6px", fontSize: "13px" }}
          className={classes.applyluptxt}
        >
          Offer 3
        </p>
        <div className={classes.applylonffergg}>
          <div className={classes.divinffe}>
            <p className={classes.txtfoofl}>Loan Amount</p>
            <p className={classes.txtfooflbtone}>₹120000</p>
          </div>
          <div className={classes.divinffe}>
            <p className={classes.txtfoofl}>Rate of Interest</p>
            <p className={classes.txtfooflbtone}>17.5%</p>
          </div>
        </div>
        <div className={classes.applylonffergg}>
          <div className={classes.divinffe}>
            <p className={classes.txtfoofl}>Terms</p>
            <p className={classes.txtfooflbtone}>16 Month</p>
          </div>
          <div className={classes.divinffe}>
            <p className={classes.txtfoofl}>EMI per Month</p>
            <p className={classes.txtfooflbtone}>₹8463.31</p>
          </div>
        </div>
        <button className={classes.acptbtn}>Accept</button>
      </div>
      {/* end */}
      {/* start */}
      <div className={classes.applylonffer} onClick={() => handleEditRow("L3")}>
        <p
          style={{ padding: "6px", fontSize: "13px" }}
          className={classes.applyluptxt}
        >
          Offer 4
        </p>
        <div className={classes.applylonffergg}>
          <div className={classes.divinffe}>
            <p className={classes.txtfoofl}>Loan Amount</p>
            <p className={classes.txtfooflbtone}>₹120000</p>
          </div>
          <div className={classes.divinffe}>
            <p className={classes.txtfoofl}>Rate of Interest</p>
            <p className={classes.txtfooflbtone}>17.5%</p>
          </div>
        </div>
        <div className={classes.applylonffergg}>
          <div className={classes.divinffe}>
            <p className={classes.txtfoofl}>Terms</p>
            <p className={classes.txtfooflbtone}>16 Month</p>
          </div>
          <div className={classes.divinffe}>
            <p className={classes.txtfoofl}>EMI per Month</p>
            <p className={classes.txtfooflbtone}>₹8463.31</p>
          </div>
        </div>
        <button className={classes.acptbtn}>Accept</button>
      </div>
      {/* end */}
    </div>
  );
};

export default AdditionalSeven;
