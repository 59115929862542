import React, { useContext, useState } from "react";
import classes from "./Language.module.css";
import Breadcum from "../../Common/Breadcum";
import { AuthContext } from "../../../context/AuthContext";
import { useForm } from "react-hook-form";
import { Backdrop } from "@mui/material";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { useNavigate } from "react-router-dom";
const ChangePW = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const { user, token, setUser, setToken } = useContext(AuthContext);
  const [add, setAdd] = useState(false);
  console.log("user", user);
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const NePw = watch("new_password");
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    let payload;
    payload = {
      new_password: data?.new_password,
      confirm_password: data?.confirm_password,
    };
    console.log("updatedData", payload);
    updateVpnMutation.mutate(payload);
  };

  const updateVpnMutation = useCustomMutation({
    url: "admin/change-admin-password",
    method: "post",
    invalidateKey: "langList",
    successCallback: () => {
      navigate("/dashboard");
    },
  });
  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      <div className={classes.mainBodyContainer}>
        <Breadcum pageName="User Verification" />
        {/* <div className={classes["main-body"]} style={{ padding: "15px" }}>
          <div className={classes.tablco}>
            </div> */}
        <div
          className={classes.tableContai}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "400px", // To take full height of the viewport
            padding: "15px",
            background: "#fff",
          }}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ width: "35%", textAlign: "left" }}
          >
            <div>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                  marginBottom: "3px",
                }}
              >
                New Password
              </label>
              <input
                placeholder="Enter New Password"
                type="password"
                {...register("new_password", {
                  required: true,
                  minLength: {
                    value: 8,
                    message: "Too Short",
                  },
                })}
                className={classes.inpforsearch}
                style={{
                  width: "100%",
                  borderRadius: "5px",
                  height: "25px",
                }}
              />
              {errors.new_password && (
                <span className={classes.error}>
                  {errors.new_password.message || "This Field is Misiing"}
                </span>
              )}
            </div>

            <div
              style={{
                marginTop: "5px",
              }}
            >
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Confirm Password
              </label>
              <input
                placeholder="Enter Confirm Password"
                type="password"
                {...register("confirm_password", {
                  required: true,
                  minLength: {
                    value: 8,
                    message: "Too Short",
                  },
                  validate: (value) =>
                    value === NePw || "Passwords do not match",
                })}
                className={classes.inpforsearch}
                style={{
                  width: "100%",
                  borderRadius: "5px",
                  height: "25px",
                }}
              />
              {errors.confirm_password && (
                <span className={classes.error}>
                  {errors.confirm_password.message || "This Field is Missing"}
                </span>
              )}
            </div>
            <button
              style={{ width: "120px", maxHeight: "30px", marginTop: "8px" }}
              className={classes.addbtn}
              type="submit"
            >
              Change Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePW;
